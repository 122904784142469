import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AcquisitionForm from './AcquisitionForm';
import { useNavigate, useParams } from 'react-router-dom';
import acquisitionService from '../../services/acquisitions/acquisitionService';
import warehouseService from '../../services/wms/warehouseService';
import itemService from '../../services/wms/itemService';
import clientService from '../../services/crm/clientService';
import useCheckPermission from '../../hooks/useCheckPermission';
import expenseTypeService from '../../services/erp/expenseTypeService';
import expenseService from '../../services/erp/expenseService';
import taxTypeService from '../../services/erp/taxTypeService';
import { EntityStatuses } from '../../types/EntityStatuses';
import { AcquisitionStatuses } from '../../types/AcquisitionStatuses';
import { Currency } from '../../types/Currency';
import {
  setForm,
  reset,
} from '../../stateManagement/actions/acquisitionActions';

const AcquisitionEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const { clientId = null, isDialog = false, onSave } = props;
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [expenseTypes, setExpenseTypes] = useState<any[]>([]);
  const [taxes, setTaxes] = useState<any[]>([]);
  const id = clientId !== null ? clientId : params.id;
  const [entity, setEntity] = useState<any>({
    id: 0,
    clientId: 0,
    companyId: 0,
    invoiceNumber: '',
    acquisitions: [],
    date: new Date(),
    description: '',
    warehouseId: '',
    otherCosts: 0,
    expenses: [],
    paymentDeadLine: new Date(),
    paymentType: '',
    orderNumber: '',
    currency: Currency.HUF,
    conversionRate: 1,
    status: AcquisitionStatuses.InProgress,
  });
  const [warehouses, setWarehouses] = useState<any[]>([{ id: '', name: '' }]);
  const [items, setItems] = useState<any[]>([
    {
      id: '',
      name: '',
      itemNumber: '',
      externalItemNumber: '',
      description: '',
    },
  ]);
  const [clients, setClients] = useState<any[]>([{ id: '', companyName: '' }]);
  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    expenseTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setExpenseTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    taxTypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setTaxes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(false, EntityStatuses.Approved)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });

    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });

    return () => {
      abort.abort();
    };
  }, []);

  const loadItemNames = (
    clientId: number | null = null,
    itemGroupId: number | null = null
  ) => {
    return itemService
      .listNames(false, clientId, itemGroupId)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setItems(response.records);
        }
      });
  };

  useEffect(() => {
    let abort = new AbortController();

    if (id) {
      acquisitionService.get(id).then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          loadItemNames(response.clientId);
          setEntity(response);
          if (response.status === AcquisitionStatuses.Draft) {
            dispatch(setForm(response));
          }
        }
      });
    }

    return () => {
      abort.abort();
    };
  }, [id]);

  const onSubmit = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    acquisitionService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          dispatch(reset());

          if (!isDialog) {
            navigate(-1);
          }
          if (isDialog) {
            onSave(response.result);
          }
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <AcquisitionForm
      entity={entity}
      readonly={
        !checkPermission(['AcquisitionEdit']) ||
        (entity.status === AcquisitionStatuses.Completed &&
          !checkPermission(['AcquisitionUpdateAfterCompletion']))
      }
      items={items}
      taxes={taxes}
      clients={clients}
      warehouses={warehouses}
      expenseTypes={expenseTypes}
      setExpenseTypes={setExpenseTypes}
      onClientChange={(clientId) => loadItemNames(clientId)}
      onNewItemAdded={(item) => setItems((items) => [...items, item])}
      onClientAdded={(client) => {
        if (clients.find((x) => x.id === client.id)) {
          setClients((clients) =>
            clients.map((x) => (x.id === client.id ? client : x))
          );
        } else {
          setClients((clients) => [...clients, client]);
        }
      }}
      onSubmit={onSubmit}
      errors={errors}
      isDialog={isDialog}
    />
  );
};

export default AcquisitionEdit;
