import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import { AttachmentTypes } from '../../../types/AttachmentTypes';
import FilesPage from '../Files/FilesPage';
import ChimneySweepingCertificatePage from './ChimneySweepingCertificatePage';
import ChimneyControlAppointmentPage from '../chimneyControlAppointments/ChimneyControlAppointmentPage';
import SerialNumberedBlockPage from '../../deviceManagement/serialNumberedBlocks/SerialNumberedBlockPage';

const ChimneySweepingCertificateTabPage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor(
    'ChimneySweepingCertificateTabPage.title'
  );
  const [tabValue, setTabValue] = React.useState(0);
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:800px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    saveTab(newValue);
  };

  useEffect(() => {
    const savedtab = localStorage.getItem(
      `chimneySweepingCertificateTabPage_tab${window.location.hash}`
    );
    if (savedtab) {
      setTabValue(JSON.parse(savedtab));
    }
  }, []);

  const saveTab = (tab) => {
    localStorage.setItem(
      `chimneySweepingCertificateTabPage_tab${window.location.hash}`,
      JSON.stringify(tab)
    );
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                {checkPermission(['ChimneySweepingCertificateView']) && (
                  <Tab
                    value={0}
                    label="Tanúsítványok"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['ViewFileAttachment']) && (
                  <Tab
                    value={1}
                    label="Tanúsítvány fájlok"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['ViewFileAttachment']) && (
                  <Tab
                    value={2}
                    label="Kiküldendő tanúsítványok"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['ChimneySweepingCertificateViewDue']) && (
                  <Tab
                    value={3}
                    label="Lejárt tanúsítványok"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['ChimneyControlAppointmentView']) && (
                  <Tab
                    value={4}
                    label="Egyeztetett kémény ellenőrzések"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['SerialNumberedBlockView']) && (
                  <Tab
                    value={5}
                    label="Tanúsítvány tömbök"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
              </Tabs>
            </Box>
            {checkPermission(['ChimneySweepingCertificateView']) &&
              tabValue === 0 && <ChimneySweepingCertificatePage />}
            {checkPermission(['ViewFileAttachment']) && tabValue === 1 && (
              <FilesPage
                attachmentType={AttachmentTypes.ChimneySweepingCertificate}
                onList={() => {}}
              />
            )}
            {checkPermission(['ChimneySweepingCertificateView']) &&
              tabValue === 2 && (
                <ChimneySweepingCertificatePage listNotSent={true} />
              )}
            {checkPermission(['ChimneySweepingCertificateViewDue']) &&
              tabValue === 3 && (
                <ChimneySweepingCertificatePage listDue={true} />
              )}
            {checkPermission(['ChimneyControlAppointmentView']) &&
              tabValue === 4 && <ChimneyControlAppointmentPage />}
            {checkPermission(['SerialNumberedBlockView']) && tabValue === 5 && (
              <SerialNumberedBlockPage filterTypeIds={[-1]} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ChimneySweepingCertificateTabPage;
