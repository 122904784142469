import axios from 'axios';
import { postData } from '../axiosUtils';

const workitemWorkItem = {
  list: (
    listInvoiceAfterwards?: boolean,
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/workitem/list`;
    if (listInvoiceAfterwards !== undefined && listInvoiceAfterwards !== null) {
      url = `${url}?invoiceAfterwards=${listInvoiceAfterwards}`;
      if (startDate) {
        url += `&startDate=${startDate?.toISOString()}`;
      }
      if (endDate) {
        url += `&endDate=${endDate?.toISOString()}`;
      }
    } else {
      if (startDate) {
        url += `?startDate=${startDate?.toISOString()}`;
        if (endDate) {
          url += `&endDate=${endDate?.toISOString()}`;
        }
      } else {
        if (endDate) {
          url += `?endDate=${endDate?.toISOString()}`;
        }
      }
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listmy: (
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/workitem/listmy`;
    if (startDate) {
      url += `?startDate=${startDate?.toISOString()}`;
      if (endDate) {
        url += `&endDate=${endDate?.toISOString()}`;
      }
    } else {
      if (endDate) {
        url += `?endDate=${endDate?.toISOString()}`;
      }
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  listNotSent: (
    startDate: any | null = null,
    endDate: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/workitem/listNotSent`;
    if (startDate) {
      url += `?startDate=${startDate?.toISOString()}`;
      if (endDate) {
        url += `&endDate=${endDate?.toISOString()}`;
      }
    } else {
      if (endDate) {
        url += `?endDate=${endDate?.toISOString()}`;
      }
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/workitem/details/${id}`).then((response) => {
      return response.data;
    });
  },
  getPdf: (entity: any) => {
    let url = `/api/workitem/getPdf`;

    return postData({
      url: url,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  sendEmail: (entity: any) => {
    return postData({
      url: `/api/workItem/sendEmail`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  sendEmailAll: (entity: any) => {
    return postData({
      url: `/api/workItem/sendEmailAll`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  listWorkItemHistory: (id: any) => {
    return axios
      .get(`/api/workitem/listWorkItemHistory/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  listClientWorkItem: (clientId: any) => {
    return axios
      .get(`/api/workitem/listClientWorkItem/${clientId}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/workitem/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  complete: (entity: any) => {
    return postData({
      url: `/api/workitem/complete`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  addInvoice: (entity: any) => {
    return postData({
      url: `/api/workitem/addInvoice`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  getUserCompletedWorkItems: (userId: any, startDate: any, endDate: any) => {
    const isoStartDate = new Date(startDate).toISOString();
    const isoEndDate = new Date(endDate).toISOString();
    return axios
      .get(
        `/api/workitem/getUserCompletedWorkItems/${userId}?startDate=${isoStartDate}&endDate=${isoEndDate}`
      )
      .then((response) => {
        return response.data;
      });
  },
  completeWorkFlow: (id: any) => {
    return postData({
      url: `/api/workitem/completeWorkFlow/${id}`,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  completeProcurement: (id: any) => {
    return postData({
      url: `/api/workitem/completeProcurement/${id}`,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  rejectWorkFlow: (entity: any) => {
    return postData({
      url: `/api/workitem/rejectWorkFlow`,
      data: entity,
      method: 'POST',
    }).then((response) => {
      return response;
    });
  },
  close: (id: any) => {
    return postData({
      url: `/api/workitem/close/${id}`,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delegate: (entity: any) => {
    return postData({
      url: `/api/workitem/delegate`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  publish: (entity: any) => {
    return postData({
      url: `/api/workitem/publish`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/workitem/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  setInvoiceAfterWards: (entity: any) => {
    return postData({
      url: `/api/workitem/setInvoiceAfterWards`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/workitem/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default workitemWorkItem;
