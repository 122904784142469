export enum DocumentLanguage {
  De = 0,

  En = 1,

  Fr = 2,

  Hr = 3,

  Hu = 4,

  It = 5,

  Ro = 6,

  Sk = 7,

  // Us = 8,
  Zh = 9,
}
