import { Box, Grid, Paper, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import WorkItemsPage from './WorkItemPage';

const WorkItemTabPage = () => {
  const navigate = useNavigate();
  const titleDescriptor = useFunctionDescriptor('WorkItemTabPage.title');
  const [tabValue, setTabValue] = React.useState(0);
  const { checkPermission } = useCheckPermission();
  const isSmallScreen = useMediaQuery('(max-width:800px)');

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    saveTab(newValue);
  };

  useEffect(() => {
    const savedtab = localStorage.getItem(
      `workItemTabPage_tab${window.location.hash}`
    );
    if (savedtab) {
      setTabValue(JSON.parse(savedtab));
    }
  }, []);

  const saveTab = (tab) => {
    localStorage.setItem(
      `workItemTabPage_tab${window.location.hash}`,
      JSON.stringify(tab)
    );
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                maxWidth: isSmallScreen ? '280px' : '100%',
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{ style: { background: '#1976d2' } }}
                sx={{
                  maxWidth: isSmallScreen ? '280px' : '100%',
                  overflowX: 'scroll',
                }}
                allowScrollButtonsMobile
                scrollButtons="auto"
                variant="scrollable"
              >
                {checkPermission(['WorkItemView']) && (
                  <Tab
                    value={0}
                    label="Munkalapok"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
                {checkPermission(['NotSentWorkItemView']) && (
                  <Tab
                    value={1}
                    label="Kiküldetlen munkalapok"
                    sx={{ fontWeight: 'bold' }}
                  />
                )}
              </Tabs>
            </Box>
            {checkPermission(['WorkItemView']) && tabValue === 0 && (
              <WorkItemsPage />
            )}
            {checkPermission(['NotSentWorkItemView']) && tabValue === 1 && (
              <WorkItemsPage listNotSent={true} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default WorkItemTabPage;
