import axios from 'axios';
import { postData } from '../axiosUtils';
import { ContractTypeScopes } from '../../types/ContractTypeScopes';

const contractService = {
  list: (
    clientId: number | null = null,
    scope: ContractTypeScopes | null = null
  ) => {
    let url = `/api/contract/list`;
    if (clientId) {
      url += `?clientId=${clientId}`;
      if (scope) {
        url += `&scope=${scope}`;
      }
    } else {
      if (scope) {
        url += `?scope=${scope}`;
      }
    }

    return axios.get(url).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios.get(`/api/contract/details/${id}`).then((response) => {
      return response.data;
    });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/contract/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/contract/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/contract/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default contractService;
