import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import ChimneyForm from './ChimneyForm';
import chimneyService from '../../../services/chimneySweeping/chimneyService';
import clientService from '../../../services/crm/clientService';
import { EntityStatuses } from '../../../types/EntityStatuses';
import chimneyTypeService from '../../../services/chimneySweeping/chimneyTypeService';

const ChimneyEdit = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const params = useParams();
  const [errors, setErrors] = useState<string[]>([]);
  const [chimneyTypes, setChimneyTypes] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [clients, setClients] = useState<any[]>([]);

  const [entity, setEntity] = useState<any>({
    id: 0,
    usingSinceDate: '',
    addressId: 0,
    clientId: 0,
    chimneyTypeId: 0,
    isInUse: '',
    hasBeenUsed: '',
    isBanned: '',
    groupNumber: '',
    lastControlYear: '',
    connectionElementLength: '',
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    chimneyService.get(params.id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setErrors(response.errorMessages);
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setEntity(response.result);
      }
    });
  }, [params.id]);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    chimneyService
      .update(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    chimneyTypeService.list().then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setChimneyTypes(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  useEffect(() => {
    clientService.list(true, EntityStatuses.Approved).then((response) => {
      if (response.canceled) return;
      if (!response.hasError) {
        setClients(response.records);
      } else {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      }
    });
  }, []);

  return (
    <ChimneyForm
      entity={entity}
      readonly={!checkPermission(['ChimneyEdit'])}
      onSubmit={onSubmit}
      handleCancel={() => navigate(-1)}
      errors={errors}
      clients={clients}
      chimneyTypes={chimneyTypes}
    />
  );
};

export default ChimneyEdit;
