import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import userService from '../../../services/authority/userService';
import chimneyControlAppointmentService from '../../../services/chimneySweeping/chimneyControlAppointmentService';
import { ChimneyControlAppointmentStatuses } from '../../../types/ChimneyControlAppointmentStatuses';
import { translateChimneyControlAppointmentStatusesName } from '../../../utils/nameFormatters';
import useConfirmOfferDialog from '../../../hooks/useConfirmOfferDialog';
import Divider from '@mui/material/Divider';
import SelectClient from '../../crm/clients/SelectClient';
import clientService from '../../../services/crm/clientService';
import { ClientTypes } from '../../../types/ClientTypes';
import { useDispatch } from 'react-redux';
import React from 'react';
import { ChimneySweepingCertificateOrderTypePicker } from '../../../components/ChimneySweepingCertificateOrderTypePicker';
import { ChimneySweepingCertOrderType } from '../../../types/ChimneySweepingCertOrderType';
import { parseJSON } from 'date-fns';

const ChimneyControlAppointmentDialog = (props: any) => {
  const {
    entity,
    onSubmit,
    readonly = false,
    open,
    onClose,
    chimneySweepingCertificateId,
    employeeId,
    isOfferMandatory = false,
    specialNeeds,
    hasClientSpecialNeeds,
  } = props;

  const [users, setUsers] = React.useState<any[]>([]);
  const [clients, setClients] = React.useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [now, setNow] = useState(new Date());
  const dispatch = useDispatch();
  const [values, setValues] = React.useState<any>({
    id: 0,
    description: '',
    chimneySweepingCertificateId: null,
    lastControlYear: 0,
    employeeId: 0,
    appointmentDate: now,
    lastControl: null,
    appointmentTimeStart: now.setHours(8, 0, 0, 0),
    appointmentTimeEnd: now.setHours(18, 0, 0, 0),
    clientId: null,
    workAddressId: null,
    billingAddressId: null,
    orderType: ChimneySweepingCertOrderType.OrderedByPhone,
    status: ChimneyControlAppointmentStatuses.Planned,
  });
  const { ConfirmOfferDialog, setOfferParams } = useConfirmOfferDialog();
  const [representative, setRepresentative] = useState<any>({
    clientId: 0,
    representativeName: '',
    representativePhone: '',
  });
  useEffect(() => {
    if (employeeId > 0) {
      setValues((prevValues) => ({ ...prevValues, employeeId: employeeId }));
    }
  }, [employeeId]);

  useEffect(() => {
    if (entity?.id > 0) {
      setValues({
        ...entity,
        appointmentTimeStart: parseJSON(entity.appointmentTimeStart),
        appointmentTimeEnd: parseJSON(entity.appointmentTimeEnd),
      });
    }
  }, [entity]);

  useEffect(() => {
    if (chimneySweepingCertificateId) {
      setValues((prevValues) => ({
        ...prevValues,
        chimneySweepingCertificateId: chimneySweepingCertificateId,
      }));
    }
  }, [chimneySweepingCertificateId]);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(true, null, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          setClients(response.records);
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
    return () => {
      abort.abort();
    };
  }, []);

  const handleClose = () => {
    setValues({
      id: 0,
      description: '',
      chimneySweepingCertificateId: null,
      lastControlYear: 0,
      employeeId: 0,
      appointmentDate: now,
      lastControl: null,
      status: ChimneyControlAppointmentStatuses.Planned,
      appointmentTimeStart: now.setHours(8, 0, 0, 0),
      appointmentTimeEnd: now.setHours(18, 0, 0, 0),
      clientId: null,
      workAddressId: null,
      orderType: ChimneySweepingCertOrderType.OrderedByPhone,
    });
    onClose();
  };

  const handleCreate = (values: any) => {
    chimneyControlAppointmentService.create(values).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres mentés!', {
          variant: 'success',
        });
        onSubmit(response.result);
        handleClose();
      }
    });
  };
  const handleUpdate = (values: any) => {
    chimneyControlAppointmentService.update(values).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres mentés!', {
          variant: 'success',
        });
        onSubmit(response.result);
        handleClose();
      }
    });
  };

  const handleClientSelected = (clientId: number) => {
    if (!(clientId > 0)) return;
    clientService.get(clientId).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setValues((prevValues) => ({
          ...prevValues,
          clientId: clientId,
          workAddressId: response.result.addresses[0]?.id ?? null,
          billingAddressId:
            response.result.addresses?.find((a) => a.isBillingAddress)?.id ?? 0,
        }));
        setRepresentative({
          clientId: response.result.id,
          representativeName: response.result.representativeName,
          representativePhone: response.result.representativePhone,
        });
      }
    });
  };

  const validate = () => {
    if (!(values.employeeId > 0)) {
      enqueueSnackbar('Kéményseprő kiválasztása kötelező!', {
        variant: 'error',
      });
      return false;
    }
    if (!(values.clientId > 0)) {
      enqueueSnackbar('Ügyfél kiválasztása kötelező!', {
        variant: 'error',
      });
      return false;
    }
    if (!values.appointmentDate) {
      enqueueSnackbar('Egyeztetett időpont megadása kötelező!', {
        variant: 'error',
      });
      return false;
    }
    if (!values.workAddressId) {
      enqueueSnackbar('Cím megadása kötelező!', {
        variant: 'error',
      });
      return false;
    }
    if (!values.appointmentTimeStart || !values.appointmentTimeEnd) {
      enqueueSnackbar('Egyeztetett időpont megadása kötelező!', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} maxWidth={'xl'}>
      <DialogContent sx={{ p: 0 }}>
        <Paper>
          <Grid container justifyContent="left" spacing={2} p={5}>
            <h2>
              Kémény ellenőrzés egyeztetés{' '}
              {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
            </h2>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
                Ügyfél
              </Divider>
              <SelectClient
                values={values}
                setFieldValue={(name: string, value: any) => {}}
                readonly={readonly}
                clients={clients}
                setRepresentative={setRepresentative}
                representative={representative}
                onClientChange={(clientId) => handleClientSelected(clientId)}
                onClientAdded={(client) => {
                  setClients([...clients, client]);
                  handleClientSelected(client.id);
                }}
                onAddressAdded={(address) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    workAddressId: address.id,
                  }));
                }}
                hideRepresentative={true}
                hideBillingAddress={true}
                onWorkAddressSelected={(addressId) => {
                  setValues((prevValues) => ({
                    ...prevValues,
                    workAddressId: addressId,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider textAlign="left" sx={{ mt: 2, mb: 2 }}>
                Egyeztetés
              </Divider>
              <Autocomplete
                disablePortal
                id="status"
                value={values.status}
                disabled={readonly}
                onChange={(event, value) => {
                  setValues({ ...values, status: value });
                }}
                getOptionLabel={(option) => {
                  return translateChimneyControlAppointmentStatusesName(
                    option as ChimneyControlAppointmentStatuses
                  );
                }}
                options={
                  Object.values(ChimneyControlAppointmentStatuses).filter((x) =>
                    Number.isFinite(x)
                  ) ?? []
                }
                renderInput={(params) => (
                  <TextField {...params} required label="Státusz" />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12} pt={1}>
              <ChimneySweepingCertificateOrderTypePicker
                value={values.orderType}
                setValue={(value) => {
                  setValues({ ...values, orderType: value });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="employeeId"
                disabled={readonly}
                value={values.employeeId ?? ''}
                onChange={(event, value) => {
                  setValues({ ...values, employeeId: value });
                }}
                getOptionLabel={(option) => {
                  var found = users.find((g) => g.id === option);
                  if (found) {
                    return `${found.id}.${found.fullName}`;
                  } else return '';
                }}
                options={users.map((g) => g.id)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Kéményseprő"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>
            {values.status ===
              ChimneyControlAppointmentStatuses.CompletedByOther && (
              <>
                <Grid item xs={12}>
                  <TextField
                    value={values.lastControlYear}
                    onChange={(event) => {
                      setValues({
                        ...values,
                        lastControlYear: event.target.value,
                      });
                    }}
                    label="Utolsó ellenőrzés éve"
                    name="lastControlYear"
                    type="number"
                    disabled={readonly}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <DatePicker
                    label="Utolsó ellenőrzés dátuma"
                    value={values.lastControl}
                    disabled={readonly}
                    onChange={(date) =>
                      setValues({
                        ...values,
                        lastControl: date,
                      })
                    }
                    renderInput={(props) => <TextField fullWidth {...props} />}
                  />
                </Grid>
              </>
            )}
            {values.status !==
              ChimneyControlAppointmentStatuses.CompletedByOther && (
              <>
                <Grid item xs={12}>
                  <DatePicker
                    label="Egyeztetett időpont"
                    value={values.appointmentDate}
                    disabled={readonly}
                    onChange={(date) =>
                      setValues({
                        ...values,
                        appointmentDate: date,
                      })
                    }
                    renderInput={(props) => <TextField fullWidth {...props} />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="-Tól"
                    value={values.appointmentTimeStart}
                    onChange={(value) =>
                      setValues({ ...values, appointmentTimeStart: value })
                    }
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                    ampm={false}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    label="-Ig"
                    value={values.appointmentTimeEnd}
                    onChange={(value) =>
                      setValues({ ...values, appointmentTimeEnd: value })
                    }
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                    ampm={false}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                value={values.description}
                onChange={(event) => {
                  setValues({ ...values, description: event.target.value });
                }}
                label="Leírás"
                name="description"
                InputLabelProps={{ shrink: true }}
                disabled={readonly}
                fullWidth
                multiline
                minRows={3}
              />
            </Grid>
            {hasClientSpecialNeeds && (
              <Grid item xs={12}>
                <TextField
                  value={specialNeeds}
                  label="Ügyfél különleges igényei"
                  name="description"
                  InputLabelProps={{ shrink: true }}
                  disabled
                  fullWidth
                  multiline
                  minRows={3}
                />
              </Grid>
            )}
            <Grid container item pt={3} justifyContent="left">
              {!readonly && (
                <Grid item p={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={readonly}
                    onClick={() => {
                      if (!validate()) return;
                      if (isOfferMandatory) {
                        setOfferParams({
                          open: true,
                          name: 'Kérjük ellenőrizze hogy készült-e árajánlat számlázást megelőzően!',
                          onConfirm: async () => {
                            if (values.id > 0) {
                              handleUpdate(values);
                            } else {
                              handleCreate(values);
                            }
                          },
                        });
                      } else {
                        if (values.id > 0) {
                          handleUpdate(values);
                        } else {
                          handleCreate(values);
                        }
                      }
                    }}
                  >
                    Mentés
                  </Button>
                </Grid>
              )}
              <Grid item p={1}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => handleClose()}
                >
                  Mégse
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <ConfirmOfferDialog />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default ChimneyControlAppointmentDialog;
