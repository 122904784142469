import { Grid, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import clientService from '../services/crm/clientService';
import { useSelector } from 'react-redux';
import { capitalizeWords } from '../utils/valueFormatters';

export default function TaxPayerSearchComponent(props: any) {
  const { onSelected, readonly, taxNumber = null } = props;

  const [taxPayer, setTaxPayer] = useState<any>(null);
  const [foundTaxPayer, setFoundTaxPayer] = useState<any>(null);
  const [queryFaild, setQueryFaild] = useState(false);

  const queryTaxPayer = (taxNumber: string) => {
    if (taxNumber.length < 8) return;
    clientService.queryTaxPayer(taxNumber).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        setFoundTaxPayer(null);
        setQueryFaild(true);
      } else {
        setFoundTaxPayer({
          ...response.result,
          taxpayerName: capitalizeWords(response.result.taxpayerName),
        });
        if (taxNumber) {
          onSelected({
            ...response.result,
            taxpayerName: capitalizeWords(response.result.taxpayerName),
          });
          setTaxPayer(capitalizeWords(response.result.taxpayerName));
          setFoundTaxPayer(null);
        }
        setQueryFaild(false);
      }
    });
  };

  useEffect(() => {
    if (taxNumber) {
      setTaxPayer(taxNumber);
      queryTaxPayer(taxNumber);
    }
  }, [taxNumber]);

  return (
    <Paper>
      <Grid container p={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            value={taxPayer}
            label="Cégkereső"
            disabled={readonly}
            sx={{ backgroundColor: 'white', borderRadius: 1 }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => {
              setTaxPayer(e.target.value);
              if (e.target.value.length >= 8) {
                queryTaxPayer(e.target.value);
              }
            }}
          />
          <Typography variant="caption">
            Irja be az adószám első 8 karakterét!
          </Typography>
        </Grid>

        {queryFaild && (
          <Grid item xs={12}>
            <Button
              variant="text"
              color="inherit"
              fullWidth
              sx={{
                justifyContent: 'left',
                backgroundColor: 'lightGrey',
              }}
            >
              Nincs találat
            </Button>
          </Grid>
        )}
        {foundTaxPayer && (
          <Grid item xs={12}>
            <Button
              variant="text"
              color="inherit"
              onClick={() => {
                setTaxPayer(foundTaxPayer.taxpayerName);
                onSelected(foundTaxPayer);
                setFoundTaxPayer(null);
              }}
              fullWidth
              sx={{
                justifyContent: 'left',
                backgroundColor: 'lightGrey',
              }}
            >
              {foundTaxPayer.taxpayerName}
            </Button>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
