import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import { ClientTypes } from '../../../types/ClientTypes';
import useCheckPermission from '../../../hooks/useCheckPermission';

const SerialNumberedBlockForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    clients,
    users,
    warehouses,
    blockTypes,
  } = props;

  const { checkPermission } = useCheckPermission();

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Számozott tömb {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="companyId"
                  disabled={values.id > 0 || readonly}
                  value={values?.companyId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('companyId', value);
                  }}
                  getOptionLabel={(option) =>
                    clients?.find((g) => g.id === option)?.companyName ?? ''
                  }
                  options={clients
                    ?.filter((y) => y.clientType === ClientTypes.Distributor)
                    .map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.companyId && !!validationErrors.companyId
                      }
                      helperText={
                        !!touched.companyId &&
                        !!validationErrors.companyId &&
                        (validationErrors.companyId as string)
                      }
                      fullWidth
                      label="Cég"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="holderId"
                  disabled={values.id > 0 || readonly}
                  value={values.holderId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('holderId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = users.find((g) => g.id === option);
                    if (found) {
                      return `${found.id}.${found.fullName}`;
                    } else return '';
                  }}
                  options={users.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Felelős"
                      required
                      fullWidth
                      error={!!touched.holderId && !!validationErrors.holderId}
                      helperText={
                        !!touched.holderId &&
                        !!validationErrors.holderId &&
                        (validationErrors.holderId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="warehouseId"
                  disabled={values.id > 0 || readonly}
                  value={values.warehouseId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('warehouseId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = warehouses.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={warehouses.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Raktár"
                      required
                      fullWidth
                      error={
                        !!touched.warehouseId && !!validationErrors.warehouseId
                      }
                      helperText={
                        !!touched.warehouseId &&
                        !!validationErrors.warehouseId &&
                        (validationErrors.warehouseId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Autocomplete
                  disablePortal
                  id="serialNumberedBlockTypeId"
                  disabled={values.id > 0 || readonly}
                  value={values.serialNumberedBlockTypeId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('serialNumberedBlockTypeId', value);
                  }}
                  getOptionLabel={(option) => {
                    var found = blockTypes.find((g) => g.id === option);
                    if (found) {
                      return `${found.name}`;
                    } else return '';
                  }}
                  options={blockTypes.map((g) => g.id)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Típus"
                      required
                      fullWidth
                      error={
                        !!touched.serialNumberedBlockTypeId &&
                        !!validationErrors.serialNumberedBlockTypeId
                      }
                      helperText={
                        !!touched.serialNumberedBlockTypeId &&
                        !!validationErrors.serialNumberedBlockTypeId &&
                        (validationErrors.serialNumberedBlockTypeId as string)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.startNumber}
                  onChange={handleChange}
                  label="Tömbszám kezdete"
                  name="startNumber"
                  required
                  type="number"
                  disabled={readonly}
                  fullWidth
                  autoFocus
                  error={
                    !!touched.startNumber && !!validationErrors.startNumber
                  }
                  helperText={
                    !!touched.startNumber &&
                    !!validationErrors.startNumber &&
                    (validationErrors.startNumber as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.endNumber}
                  onChange={handleChange}
                  label="Tömbszám vége"
                  name="endNumber"
                  type="number"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.endNumber && !!validationErrors.endNumber}
                  helperText={
                    !!touched.endNumber &&
                    !!validationErrors.endNumber &&
                    (validationErrors.endNumber as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.netPrice}
                  onChange={handleChange}
                  label="Nettó ár"
                  name="netPrice"
                  required
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={!!touched.netPrice && !!validationErrors.netPrice}
                  helperText={
                    !!touched.netPrice &&
                    !!validationErrors.netPrice &&
                    (validationErrors.netPrice as string)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Nyitás dátuma"
                  value={values.openingDate ?? ''}
                  onChange={(date) => setFieldValue('openingDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={'auto'} p={1}>
                <DatePicker
                  label="Zárás dátuma"
                  value={values.closingDate}
                  onChange={(date) => setFieldValue('closingDate', date)}
                  renderInput={(props) => <TextField {...props} />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.isClosed}
                      onChange={(e, checked) =>
                        setFieldValue('isClosed', checked)
                      }
                      color="primary"
                    />
                  }
                  label="Lezárt tömb?"
                />
              </Grid>
              {!(values.id > 0) &&
                checkPermission(['SerialNumberedBlockMassCreate']) && (
                  <>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.isMassCreate}
                            onChange={(e, checked) =>
                              setFieldValue('isMassCreate', checked)
                            }
                            color="primary"
                          />
                        }
                        label="Tömeges feltöltés?"
                      />
                    </Grid>
                    {values.isMassCreate && (
                      <Grid item xs={12}>
                        <TextField
                          value={values.numberOfPages}
                          onChange={(e) =>
                            setFieldValue('numberOfPages', e.target.value)
                          }
                          label="Lapok száma tömbönként"
                          type="number"
                          InputLabelProps={{ shrink: true }}
                          disabled={readonly}
                          fullWidth
                          error={
                            !!touched.numberOfPages &&
                            !!validationErrors.numberOfPages
                          }
                          helperText={
                            !!touched.numberOfPages &&
                            !!validationErrors.numberOfPages &&
                            (validationErrors.numberOfPages as string)
                          }
                        />
                      </Grid>
                    )}
                  </>
                )}
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                <Grid item p={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleCancel}
                  >
                    Mégse
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default SerialNumberedBlockForm;
