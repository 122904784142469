import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import AddressesForm from './AddressesForm';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

export default function CombineAddressesDialog(props: any) {
  const { open, onClose, onAgreed, addresses } = props;
  const [selectedAddresses, setSelectedAddresses] = useState<any[]>([]);
  const [addressIdToKeep, setAddressIdToKeep] = useState<any>(null);
  const handleClose = () => {
    setSelectedAddresses([]);
    setAddressIdToKeep(null);
    onClose();
  };
  const { enqueueSnackbar } = useSnackbar();

  const validate = () => {
    if (!addressIdToKeep) {
      enqueueSnackbar('Megtartott cím megadása kötelező', { variant: 'error' });
      return false;
    }
    if (selectedAddresses.length === 0) {
      enqueueSnackbar('Legalább egy címet ki kell választani', {
        variant: 'error',
      });
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={() => handleClose()} fullWidth maxWidth={'lg'}>
      <DialogTitle>Talált címek</DialogTitle>
      <DialogContent>
        <Grid item xs={12} pt={2}>
          <Grid item xs={12} pb={5}>
            <Autocomplete
              value={addressIdToKeep}
              onChange={(event, value) => {
                setAddressIdToKeep(value);
              }}
              getOptionLabel={(option) => {
                const address = addresses?.find((g) => g.id === option);
                return address ? `${address.fullAddress} (${address.id})` : '';
              }}
              options={addresses?.map((g) => g.id)} // Use filtered options
              filterOptions={(options, params) => {
                const searchTermLower = params.inputValue.toLowerCase();
                const searchTokens = searchTermLower.split(/\s+/);

                const filteredItems = addresses?.filter((address) => {
                  const name = address?.fullAddress?.toLowerCase() || '';
                  return searchTokens.every((token) => name.includes(token));
                });

                return filteredItems.map((address) => address.id);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Megtartott cím"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          {addressIdToKeep > 0 && (
            <Grid item xs={12}>
              <AddressesForm
                values={{
                  addresses: addresses.filter((g) => g.id !== addressIdToKeep),
                }}
                setFieldValue={() => {}}
                errors={{}}
                touched={{}}
                readonly={true}
                isSelectable
                setSelectedAddresses={setSelectedAddresses}
                selectedAddresses={selectedAddresses}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (validate()) {
              onAgreed(selectedAddresses, addressIdToKeep);
              handleClose();
            }
          }}
        >
          Elfogad
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
