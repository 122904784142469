import moment from 'moment';
import { Currency } from '../types/Currency';

export const capitalizeWords = (str) => {
  let result = str
    .toLowerCase() // Convert the entire string to lowercase first
    .split(' ') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the array back into a single string
  return result;
};

//formats value to currency in hungarian forint it support string and number rounded to 0 decimal
export const formatCurrency = (
  value: string | number,
  currency: Currency = Currency.HUF
) => {
  return new Intl.NumberFormat('hu-HU', {
    style: 'currency',
    currency: currency ? Currency[currency] : 'HUF',
    minimumFractionDigits: currency === Currency.HUF ? 0 : 2,
    maximumFractionDigits: currency === Currency.HUF ? 0 : 2,
  }).format(Number(value));
};
export const currencyAdornment = (currency: Currency = Currency.HUF) => {
  return currency === Currency.HUF ? 'Ft' : Currency[currency];
};

export const formatTime = (params: string | { value: string } = 'timeSpan') => {
  // If params is a string, create an object with a value property set to the string value
  const value = typeof params === 'string' ? params : params?.value;
  if (value === null || value === undefined || value === '') {
    return 'Nincs';
  }

  // Create a Date object from the input value, assuming it is in UTC format
  const date = new Date(value);
  const milliseconds = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  const localTime = new Date(milliseconds);

  // Format the local time as HH:mm
  const formattedTime = localTime.toLocaleTimeString(undefined, {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  // Return the formatted time
  return formattedTime;
};

export const formatRole = (params) => {
  return params === 'Dispatcher'
    ? 'Diszpécser'
    : params === 'Surveyor'
      ? 'Felmérő'
      : params === 'OfferGiver'
        ? 'Ajánlatkészítő'
        : params === 'ProcurementSpecialist'
          ? 'Anyagbeszerző'
          : params === 'Warehouseman'
            ? 'Előkészítő'
            : params === 'Mechanic'
              ? 'Szerelő'
              : params === 'Evaluator'
                ? 'Kiértékelő'
                : null;
};
