import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Done,
  Edit as EditIcon,
  HighlightOff,
  Rule,
  Visibility,
} from '@mui/icons-material';
import ArticleIcon from '@mui/icons-material/Article';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import {
  Autocomplete,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import serialnumberedblockService from '../../../services/deviceManagement/serialNumberedBlockService';
import serialnumberedBlocktypeService from '../../../services/deviceManagement/serialNumberedBlockTypeService';
import CompanyAssetLogDialog from '../CompanyAssetLogDialog';
import MoveAssetDialog from '../MoveAssetDialog';
import MissingChimneySweepingCertificateDialog from './MissingChimneySweepingCertificateDialog';

export interface SerialNumberedBlock {
  serialNumber: string;
  type: string;
  oSVersion: string;
  other: string;
  purchaseDate: Date;
}

const SerialNumberedBlockPage = (props: any) => {
  const { filterTypeIds = null } = props;
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const [filteredRows, setFilteredRows] = useState<any[]>([]);
  const [types, setTypes] = useState<any[]>([]);
  const [selectedFilterType, setSelectedFilterType] = useState<any>(null);
  const [isClosedFilter, setIsClosedFilter] = useState<any>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [missingCertDialog, setMissingCertDialog] = useState<any>({
    open: false,
    serialNumberedBlockId: 0,
  });
  const [assetLogDialog, setAssetLogDialog] = useState<any>({
    open: false,
    companyAssetId: 0,
  });
  const dispatch = useDispatch<any>();
  const [moveAssetDialog, setMoveAssetDialog] = useState<any>({
    open: false,
    entity: { assetId: 0, userId: 0, companyId: 0, warehouseId: 0 },
  });
  const titleDescriptor = useFunctionDescriptor(
    'SerialNumberedBlocksPage.title'
  );

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    if (filterTypeIds !== null) {
      setSelectedFilterType(filterTypeIds[0]);
    }
  }, [filterTypeIds]);

  useEffect(() => {
    if (isClosedFilter === true) {
      setFilteredRows(rows.filter((row) => row.isClosed));
    } else if (isClosedFilter === false) {
      setFilteredRows(rows.filter((row) => !row.isClosed));
    } else {
      setFilteredRows(rows);
    }
  }, [rows, isClosedFilter]);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    serialnumberedblockService
      .list(selectedFilterType, abort.signal)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, [selectedFilterType]);

  useEffect(() => {
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    serialnumberedBlocktypeService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setTypes(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    if (params.row.serialNumberedBlockTypeId === -1) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Hiányzó tanúsítványok">
              <Rule />
            </Tooltip>
          }
          label="Hiányzó tanúsítványok"
          onClick={() =>
            setMissingCertDialog({
              open: true,
              serialNumberedBlockId: params.row.id,
            })
          }
          showInMenu
        />
      );
    }

    if (checkPermission(['CompanyAssetMove'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Mozgatás">
              <DriveFileMoveIcon />
            </Tooltip>
          }
          label="Mozgatás"
          onClick={() =>
            setMoveAssetDialog({
              open: true,
              entity: {
                assetId: params.row.companyAssetId,
                userId: params.row.holderId,
                companyId: params.row.companyId,
                warehouseId: params.row.warehouseId,
              },
            })
          }
          showInMenu
        />
      );
    }

    let readonly = !checkPermission(['SerialNumberedBlockEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() =>
          navigate(`/erp/serialnumberedblocks/edit/${params.row.id}`)
        }
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title="Napló">
            <ArticleIcon />
          </Tooltip>
        }
        label={'Napló'}
        onClick={() =>
          setAssetLogDialog({
            open: true,
            companyAssetId: params.row.companyAssetId,
          })
        }
        showInMenu
      />
    );
    if (checkPermission(['SerialNumberedBlockClose']) && !params.row.isClosed) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Lezárás">
              <HighlightOff />
            </Tooltip>
          }
          label="Lezárás"
          onClick={() => setClosed(params.row, true)}
          showInMenu
        />
      );
    }
    if (checkPermission(['SerialNumberedBlockClose']) && params.row.isClosed) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Nyitás">
              <Done />
            </Tooltip>
          }
          label="Nyitás"
          onClick={() => setClosed(params.row, false)}
          showInMenu
        />
      );
    }
    if (checkPermission(['SerialNumberedBlockDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
          showInMenu
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'isClosed',
      headerName: 'Lezárt',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    { field: 'numberRange', headerName: 'Tömbszámozás', flex: 150 },
    {
      field: 'openingDate',
      headerName: 'Nyitás dátuma',
      valueFormatter(params) {
        return new Date(params.value as Date).toLocaleDateString();
      },
      flex: 150,
    },
    {
      field: 'closingDate',
      headerName: 'Zárás dátuma',
      valueFormatter(params) {
        return params.value
          ? new Date(params.value as Date).toLocaleDateString()
          : 'Nincs';
      },
      flex: 150,
    },
    { field: 'companyName', headerName: 'Cég', flex: 200 },
    { field: 'warehouseName', headerName: 'Raktár', flex: 200 },
    { field: 'holderName', headerName: 'Felelős', flex: 200 },
    { field: 'typeName', headerName: 'Típus', flex: 100 },
    {
      field: 'actions',
      type: 'actions',
      align: 'right',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    serialnumberedblockService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  const setClosed = (entity: any, isCLosed: boolean) => {
    dispatch({ type: 'SHOW_SAVE' });
    serialnumberedblockService
      .update({ ...entity, isClosed: isCLosed })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          setRows(
            rows.map((row) =>
              row.id === entity.id ? { ...row, isClosed: isCLosed } : row
            )
          );
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Számozott tömbök{titleDescriptor}</h2>
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid item pb={2}>
            <Grid item container spacing={2}>
              <Grid item>
                <Autocomplete
                  value={selectedFilterType}
                  options={[
                    null,
                    ...types
                      ?.filter((x) =>
                        filterTypeIds !== null
                          ? filterTypeIds.includes(x.id)
                          : true
                      )
                      ?.map((x) => x.id),
                  ]}
                  getOptionLabel={(option) => {
                    return option === null
                      ? 'Mindegy'
                      : (types.find((x) => x.id === option)?.name ?? '');
                  }}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Típus szűrő"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    setSelectedFilterType(newValue);
                  }}
                />
              </Grid>
              <Grid item>
                {' '}
                <Autocomplete
                  value={isClosedFilter}
                  options={[null, true, false]}
                  getOptionLabel={(option) => {
                    return option === null
                      ? 'Mindegy'
                      : option
                        ? 'Lezárt'
                        : 'Nyitott';
                  }}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Státusz szűrő"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                  onChange={(event, newValue) => {
                    setIsClosedFilter(newValue);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {checkPermission(['SerialNumberedBlockCreate']) && (
            <Grid container item xs={12} p={2} justifyContent="end">
              <Grid item>
                <Tooltip title="Számozott tömbök létrehozása">
                  <IconButton
                    component={RouterLink}
                    to={`/erp/serialnumberedblocks/create`}
                    color="primary"
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={filteredRows}
            columns={columns}
            localStorageKey={'SerialNumberedBlockPage'}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <MoveAssetDialog
          open={moveAssetDialog.open}
          baseEntity={moveAssetDialog.entity}
          close={() => {
            setMoveAssetDialog({
              open: false,
              entity: { assetId: 0, userId: 0, companyId: 0, warehouseId: 0 },
            });
            dispatch({ type: 'SHOW_QUERY' });
            serialnumberedblockService
              .list()
              .then((response) => {
                if (response.canceled) return;
                if (!response.hasError) setRows(response.records);
                else
                  enqueueSnackbar(response.errorMessages.join(','), {
                    variant: 'error',
                  });
              })
              .finally(() => dispatch({ type: 'HIDE' }));
          }}
        />
        <CompanyAssetLogDialog
          open={assetLogDialog.open}
          assetId={assetLogDialog.companyAssetId}
          setOpen={setAssetLogDialog}
        />
        <MissingChimneySweepingCertificateDialog
          open={missingCertDialog.open}
          serialNumberedBlockId={missingCertDialog.serialNumberedBlockId}
          setOpen={() =>
            setMissingCertDialog({ open: false, serialNumberedBlockId: 0 })
          }
        />
      </Grid>
    </Paper>
  );
};

export default SerialNumberedBlockPage;
