import { Add, Edit as EditIcon, Visibility } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChimneySweepingCertificateEdit from '../pages/chimneySweeping/chimneySweepingCertificate/ChimneySweepingCertificateEdit';
import ClientEdit from '../pages/crm/clients/ClientEdit';
import ClientProfileTabPage from '../pages/crm/clients/clientProfile/ClientProfileTabPage';
import { EntityTypes } from '../types/EntityTypes';
import AcquisitionEdit from '../pages/acquisitions/AcquisitionEdit';
import ExpenseEdit from '../pages/erp/expenses/ExpenseEdit';
import ServiceEdit from '../pages/erp/services/ServiceEdit';
import ChimneyTypeEdit from '../pages/chimneySweeping/chimneyTypes/ChimneyTypeEdit';
import { set } from 'date-fns';
import ItemEdit from '../pages/wms/items/ItemEdit';
import ItemGroupEdit from '../pages/wms/itemGroups/ItemGroupEdit';
import ItemGroupCreate from '../pages/wms/itemGroups/ItemGroupCreate';
import { useDispatch } from 'react-redux';
import { reset } from '../stateManagement/actions/acquisitionActions';

export interface EntityNavigatorProps {
  entityType: EntityTypes;
  entityId: number;
  value: any;
  openInDialog?: boolean;
  showIcon?: boolean;
  readonly?: boolean;
  isMobileView?: boolean;
  disableLink?: boolean;
  setDisplay?: string;
  isCreate?: boolean;

  onSave?: (result: any) => void;
}

const EntityNavigator = (props: EntityNavigatorProps) => {
  const {
    entityType,
    isMobileView = false,
    entityId,
    value,
    openInDialog = true,
    showIcon = false,
    readonly = false,
    isCreate = false,
    disableLink = false,
    setDisplay = 'block',
    onSave = () => {},
  } = props;
  const navigate = useNavigate();
  const [dialogProps, setDialogProps] = React.useState<any>({
    open: false,
    component: null,
  });
  const isNotSmallScreen = useMediaQuery('(min-width:800px)');
  const dispatch = useDispatch();

  const save = (result: any) => {
    onSave(result);
    setDialogProps({ open: false, component: null });
  };

  useEffect(() => {
    console.log('EntityNavigator', entityId);
  }, [entityType, entityId]);

  const navigateToEntity = (entityType: EntityTypes, entityId: number) => {
    if (disableLink) return;
    if (openInDialog) {
      switch (entityType) {
        case EntityTypes.Client:
          setDialogProps({
            open: true,
            component: (
              <ClientProfileTabPage isDialog={true} clientId={entityId} />
            ),
          });
          break;
        case EntityTypes.ClientEdit:
          setDialogProps({
            open: true,
            component: (
              <ClientEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.ItemGroupEdit:
          setDialogProps({
            open: true,
            component: (
              <ItemGroupEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.ItemGroupCreate:
          setDialogProps({
            open: true,
            component: (
              <ItemGroupCreate
                isDialog
                onSave={(result) => save(result)}
                parentId={entityId}
              />
            ),
          });
          break;
        case EntityTypes.ChimneySweepingCertificate:
          setDialogProps({
            open: true,
            component: (
              <ChimneySweepingCertificateEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.Acquisiton:
          setDialogProps({
            open: true,
            component: (
              <AcquisitionEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.Expense:
          setDialogProps({
            open: true,
            component: (
              <ExpenseEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.Service:
          setDialogProps({
            open: true,
            component: (
              <ServiceEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.ChimneyType:
          setDialogProps({
            open: true,
            component: (
              <ChimneyTypeEdit
                isDialog
                clientId={entityId}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
        case EntityTypes.Item:
          setDialogProps({
            open: true,
            component: (
              <ItemEdit
                isDialog
                clientId={entityId}
                navigateBack={false}
                onSave={(result) => save(result)}
              />
            ),
          });
          break;
      }
    } else {
      switch (entityType) {
        case EntityTypes.Client:
          navigate(`/crm/clients/profile/${entityId}`);
          break;
        case EntityTypes.ClientEdit:
          navigate(`/crm/clients/edit/${entityId}`);
          break;
        case EntityTypes.ChimneySweepingCertificate:
          navigate(`/erp/chimneySweepingCertificates/edit/${entityId}`);
          break;
        case EntityTypes.Acquisiton:
          navigate(`/acquisitions/edit/${entityId}`);
          break;
        case EntityTypes.Expense:
          navigate(`/erp/expenses/edit/${entityId}`);
          break;
        case EntityTypes.Service:
          navigate(`/erp/services/edit/${entityId}`);
          break;
        case EntityTypes.ChimneyType:
          navigate(`/erp/chimneyTypes/edit/${entityId}`);
          break;
        case EntityTypes.Item:
          navigate(`/wms/items/edit/${entityId}`);
          break;
      }
    }
  };

  const onClose = () => {
    if (entityType === EntityTypes.Acquisiton) {
      dispatch(reset());
    }
    setDialogProps({ open: false, component: null });
  };

  return (
    <Grid
      sx={{
        textAlign: 'left',
        textTransform: 'none',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: setDisplay,
      }}
    >
      <Tooltip
        children={
          showIcon ? (
            <IconButton
              color={'primary'}
              style={{
                color: isMobileView ? 'white' : 'primary',
              }}
              onClick={() => {
                navigateToEntity(entityType, entityId);
              }}
            >
              {isCreate ? <Add /> : readonly ? <Visibility /> : <EditIcon />}
            </IconButton>
          ) : (
            <Button
              variant="text"
              sx={{
                whiteSpace: isNotSmallScreen ? 'nowrap' : 'normal',
                wordBreak: isNotSmallScreen ? 'unset' : 'break-word',
              }}
              onClick={() => navigateToEntity(entityType, entityId)}
            >
              {value}
            </Button>
          )
        }
        title={`${value}`}
      />
      <Dialog
        open={dialogProps.open}
        onClose={() => onClose()}
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent
          sx={{
            p: 0,
            backgroundColor:
              entityType === EntityTypes.ClientEdit ? '#EFEFEF' : 'white',
          }}
        >
          {dialogProps.component}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor:
              entityType === EntityTypes.ClientEdit ? '#EFEFEF' : 'white',
          }}
        >
          <Button variant="contained" color="error" onClick={() => onClose()}>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default EntityNavigator;
