import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Autocomplete,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import ReactDiffViewer from 'react-diff-viewer-continued';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as Yup from 'yup';
import { CollapsePaper } from '../../../components/CollapsePaper';
import TaxPayerSearchComponent from '../../../components/TaxPayerSearchComponent';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { ClientTaxType } from '../../../types/ClientTaxType';
import { ClientTypes } from '../../../types/ClientTypes';
import { translateClientTaxTypeName } from '../../../utils/nameFormatters';
import AddressesForm from './AddressesForm';
import ContactForm from './ContactForm';
import NameForm from './NameForm';
import SyncAddressesDialog from './SyncAddressesDialog';
import clientService from '../../../services/crm/clientService';
import { useDispatch, useSelector } from 'react-redux';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Cancel } from '@mui/icons-material';
import { ProjectStatuses } from '../../../types/ProjectStatuses';
import { ApiKeyType } from '../../../types/ApiKeyType';
import { capitalizeWords } from '../../../utils/valueFormatters';
import CombineAddressesDialog from './CombineAddressesDialog';

const ClientForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    isDialog,
    externalTaxNumber = null,
  } = props;
  const [isRepresentative, setIsRepResentative] = useState(false);
  const [hasGroupTaxNumber, setHasGroupTaxNumber] = useState(false);
  const [addressCombineDialog, setAddressCombineDialog] = useState(false);
  const userDistributor = useSelector((state: any) => state.userDistributor);
  const navigate = useNavigate();
  const [syncAddressesDialogOpen, setSyncAddressesDialogOpen] = useState<any>({
    open: false,
    addresses: [],
  });
  useEffect(() => {
    setHasGroupTaxNumber(
      entity?.groupMemberTaxNumber && entity?.groupMemberTaxNumber?.length > 0
    );
  }, [entity]);

  const queryTaxPayerAddresses = (
    taxNumber: string,
    isValidationOnly: boolean,
    setFieldValue: any,
    values: any
  ) => {
    if (taxNumber.length < 8) {
      enqueueSnackbar('Az adószámnak minimum 8 karakternek kell lennie!', {
        variant: 'error',
      });
      return;
    }
    let array = values.addresses ?? [];
    let minId =
      array.length > 0
        ? array.map((x) => x.id)?.reduce((a, b) => Math.min(a, b))
        : 0;
    let count = 0;
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .queryTaxPayer(taxNumber.substring(0, 8))
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
          setFieldValue('isTaxNumberInvalid', true);
        } else {
          if (!isValidationOnly) {
            setSyncAddressesDialogOpen({
              open: true,
              addresses:
                response.result.addresses?.map((x: any) => {
                  count++;
                  return {
                    ...x,
                    city: capitalizeWords(x.city ?? ''),
                    street: capitalizeWords(x.street ?? ''),
                    id: minId > 0 ? -1 : minId - count,
                  };
                }) ?? [],
            });
          }
          setFieldValue('isTaxNumberInvalid', false);
          enqueueSnackbar('Az adószám létezik!', {
            variant: 'success',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    clientTaxType: Yup.mixed()
      .oneOf(Object.values(ClientTaxType))
      .required('Client tax type is required'),
    taxNumber: Yup.string().when('clientTaxType', {
      is: (clientTaxType) => clientTaxType !== ClientTaxType.HasNoTaxNumber,
      then: Yup.string().required('Kötelező mező!'),
      otherwise: Yup.string(),
    }),
    companyName: Yup.string().when('isPrivatePerson', {
      is: false,
      then: Yup.string().required('Kötelező mező!'),
    }),

    addresses: Yup.array()
      .of(
        Yup.object().shape({
          city: Yup.string().required('Kötelező mező!'),
          country: Yup.string().required('Kötelező mező!'),
          street: Yup.string().required('Kötelező mező!'),
          postalCode: Yup.string().required('Kötelező mező!'),
        })
      )
      .min(1, 'Egy számlázási cím megadása kötelező!')
      .test(
        'at-least-one-billing',
        'Legalább egy számlázási cím megadása kötelező!',
        (addresses) =>
          addresses.some((address) => address.isBillingAddress === true)
      ),
  });

  const showDifference = (string1: string, string2: string) => {
    if (string1 === string2) return;
    return (
      <ReactDiffViewer
        oldValue={string1 ?? ''}
        newValue={string2 ?? ''}
        splitView={false}
        hideLineNumbers
      />
    );
  };

  const deleteApiKey = (setFieldValue: any) => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .deleteApiKey(entity.id)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setFieldValue('apiKeyDescription', '');
          setFieldValue('apiKey', '');
          setFieldValue('apiKeyName', '');
          setFieldValue('hasApiKey', false);
          enqueueSnackbar('Sikeres törlés', {
            variant: 'success',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  };

  const combineAddressHandler = (
    addressIdToKeep: any,
    selectedAddresses: any
  ) => {
    if (addressIdToKeep > 0 && selectedAddresses.length > 0) {
      dispatch({ type: 'SHOW_QUERY' });
      clientService
        .combineClientAddress({
          addressIdToKeep: addressIdToKeep,
          addressIds: selectedAddresses?.map((x: any) => x.id),
        })
        .then((response) => {
          if (response.canceled) return;
          if (response.hasError) {
            enqueueSnackbar(response.errorMessages.join(','), {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Címek összevonva!', {
              variant: 'success',
            });
          }
        })
        .finally(() => dispatch({ type: 'HIDE' }));
    }
  };

  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        handleChange,
        setFieldValue,
        errors: validationErrors,
        isValid,
      }) => (
        <Form noValidate>
          <div>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <Grid
                container
                item
                xs={12}
                alignItems="center"
                justifyContent="center"
              >
                {values.id > 0 && (
                  <Grid item>
                    <IconButton
                      onClick={() =>
                        navigate(`/crm/clients/profile/${values.id}`)
                      }
                      color="primary"
                    >
                      <Tooltip title="Ügyfélprofil">
                        <AccountCircleIcon fontSize="large" />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                )}
                <Grid item>
                  <h2>
                    Ügyfél {values.id > 0 ? 'szerkesztése' : 'létrehozása'}
                  </h2>
                </Grid>
              </Grid>

              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors.map((error) => (
                    <li>{error}</li>
                  ))}
                  {validationErrors.addresses &&
                    typeof validationErrors.addresses === 'string' && (
                      <Grid item xs={12}>
                        <Typography color="error">
                          {validationErrors.addresses as string}
                        </Typography>
                      </Grid>
                    )}
                </Box>
              </Grid>
              <Grid item container xs={12} justifyContent={'center'}>
                <Grid item>
                  <Stack direction="row" alignItems="center">
                    <Typography>Szervezet</Typography>
                    <Switch
                      checked={values.isPrivatePerson ?? true}
                      disabled={readonly || values.id > 0}
                      onChange={(e) =>
                        setFieldValue('isPrivatePerson', e.target.checked)
                      }
                    />
                    <Typography>Magánszemély</Typography>
                  </Stack>
                </Grid>
                <Grid item px={5}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="clientType-label"
                      value={values.clientType}
                      name="clientType-group"
                      onChange={(e) => {
                        setFieldValue('clientType', e.target.value);
                      }}
                      row
                    >
                      <FormControlLabel
                        value={ClientTypes.Supplier}
                        control={<Radio />}
                        label="Beszállító"
                      />
                      <FormControlLabel
                        value={ClientTypes.Customer}
                        control={<Radio />}
                        label="Vásárló"
                      />
                      <FormControlLabel
                        value={ClientTypes.Distributor}
                        disabled={
                          !checkPermission(['CreateDistributor']) ||
                          values.isPrivatePerson
                        }
                        control={<Radio />}
                        label="Számlakibocsátó"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              {checkPermission(['QueryTaxPayer']) && (
                <Grid item xs={12}>
                  <TaxPayerSearchComponent
                    taxNumber={externalTaxNumber}
                    readonly={readonly}
                    onSelected={(foundTaxPayer: any) => {
                      setFieldValue('companyName', foundTaxPayer.taxpayerName);
                      setFieldValue('isPrivatePerson', false);
                      if (foundTaxPayer.groupTaxNumber.length > 0) {
                        setFieldValue(
                          'groupMemberTaxNumber',
                          foundTaxPayer.groupTaxNumber +
                            '-' +
                            foundTaxPayer.groupVatCode +
                            '-' +
                            foundTaxPayer.groupCountryCode
                        );
                        setHasGroupTaxNumber(true);
                      }
                      if (foundTaxPayer.taxNumber.length > 0) {
                        setFieldValue(
                          'taxNumber',
                          foundTaxPayer.taxNumber +
                            '-' +
                            foundTaxPayer.vatCode +
                            '-' +
                            foundTaxPayer.countryCode
                        );
                      }
                    }}
                  />
                </Grid>
              )}
              {checkPermission(['ClientHandleApiKey']) &&
                parseInt(values.clientType) === ClientTypes.Distributor && (
                  <Grid item xs={12}>
                    <CollapsePaper
                      title={
                        <Typography
                          textAlign="left"
                          fontWeight={'bold'}
                          fontSize={20}
                          sx={{ mt: 2 }}
                        >
                          Számlázási adatok
                        </Typography>
                      }
                      collapsed={false}
                      children={
                        <Grid container p={2} spacing={2} textAlign={'center'}>
                          <Grid item xs={3} pt={2}>
                            <TextField
                              disabled={readonly || values.hasApiKey}
                              value={values?.apiKeyName}
                              fullWidth
                              onChange={(e) =>
                                setFieldValue('apiKeyName', e.target.value)
                              }
                              label="Api kulcs neve"
                            />
                            {showDifference(
                              `${entity.apiKeyName ?? ''}`,
                              `${values.apiKeyName ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={5} pt={2}>
                            <TextField
                              disabled={readonly || values.hasApiKey}
                              value={values?.apiKey}
                              fullWidth
                              onChange={(e) =>
                                setFieldValue('apiKey', e.target.value)
                              }
                              label="Api kulcs"
                            />
                            {showDifference(
                              `${entity.apiKey ?? ''}`,
                              `${values.apiKey ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              disablePortal
                              disabled={readonly || values.hasApiKey}
                              value={values?.apiKeyType}
                              onChange={(event, value) => {
                                setFieldValue('apiKeyType', value);
                              }}
                              getOptionLabel={(option) => {
                                return option === ApiKeyType.Billingo
                                  ? 'Billingo'
                                  : option === ApiKeyType.Szamlazzhu
                                    ? 'Számlázz.hu'
                                    : '';
                              }}
                              options={
                                Object.values(ApiKeyType).filter((x) =>
                                  Number.isFinite(x)
                                ) ?? []
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  required
                                  fullWidth
                                  error={
                                    !!touched.type && !!validationErrors.type
                                  }
                                  helperText={
                                    !!touched.type &&
                                    !!validationErrors.type &&
                                    (validationErrors.type as string)
                                  }
                                  label="Típus"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={1} pt={2}>
                            <IconButton
                              onClick={() => deleteApiKey(setFieldValue)}
                              disabled={readonly || !values.hasApiKey}
                            >
                              <Tooltip title="Törlés">
                                <Cancel color="error" />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                          <Grid item xs={12} pt={2}>
                            <TextField
                              disabled={readonly || values.hasApiKey}
                              value={values?.apiKeyDescription}
                              fullWidth
                              multiline
                              minRows={3}
                              onChange={(e) =>
                                setFieldValue(
                                  'apiKeyDescription',
                                  e.target.value
                                )
                              }
                              label="Leírás"
                            />
                            {showDifference(
                              `${entity.apiKeyDescription ?? ''}`,
                              `${values.apiKeyDescription ?? ''}`
                            )}
                          </Grid>
                        </Grid>
                      }
                    />
                  </Grid>
                )}
              <Grid item xs={12}>
                <CollapsePaper
                  title={
                    <Typography
                      textAlign="left"
                      fontWeight={'bold'}
                      fontSize={20}
                      sx={{ mt: 2 }}
                    >
                      {values.isPrivatePerson ? 'Ügyfél' : 'Kapcsolattartó'}
                    </Typography>
                  }
                  collapsed={false}
                  children={
                    <Grid container p={2}>
                      <Grid item>
                        <NameForm
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={validationErrors}
                          touched={touched}
                          readonly={readonly}
                          showDifference={showDifference}
                          entity={entity}
                        />
                      </Grid>
                      <Grid item xs={12} pt={2}>
                        <TextField
                          name="customerLedgerNumber"
                          disabled={readonly}
                          value={values?.customerLedgerNumber}
                          onChange={(e) =>
                            setFieldValue(
                              'customerLedgerNumber',
                              e.target.value
                            )
                          }
                          label="Vevő főkönyvi szám"
                        />
                        {showDifference(
                          `${entity.customerLedgerNumber ?? ''}`,
                          `${values.customerLedgerNumber ?? ''}`
                        )}
                      </Grid>
                      {values.isPrivatePerson && (
                        <Grid item xs={12} pt={2}>
                          <TextField
                            value={values.comment ?? ''}
                            multiline
                            rows={5}
                            onChange={handleChange}
                            label="Megjegyzések"
                            name="comment"
                            fullWidth
                            disabled={readonly}
                            error={
                              !!touched.comment && !!validationErrors.comment
                            }
                            helperText={
                              !!touched.comment &&
                              !!(validationErrors.comment as string) &&
                              (validationErrors.comment as string)
                            }
                          />
                          {showDifference(
                            `${entity.comment ?? ''}`,
                            `${values.comment ?? ''}`
                          )}
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CollapsePaper
                  title={
                    <Typography
                      textAlign="left"
                      fontWeight={'bold'}
                      fontSize={20}
                      sx={{ mt: 2 }}
                    >
                      Elérhetőségek
                    </Typography>
                  }
                  collapsed={false}
                  children={
                    <Grid container p={2}>
                      <Grid item>
                        <ContactForm
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={validationErrors}
                          touched={touched}
                          readonly={readonly}
                          showDifference={showDifference}
                          entity={entity}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<Checkbox />}
                          onChange={(event, checked) =>
                            setIsRepResentative(checked)
                          }
                          label="Kapcsolattartó"
                        />
                      </Grid>
                      {isRepresentative && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Divider textAlign="left">
                              Kapcsolattartó adatai
                            </Divider>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              name="lastName"
                              disabled={readonly}
                              value={values?.representativeName?.lastName}
                              onChange={(e) =>
                                setFieldValue(
                                  'representativeName.lastName',
                                  e.target.value
                                )
                              }
                              label="Vezetéknév"
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              name="firstName"
                              disabled={readonly}
                              value={values?.representativeName?.firstName}
                              onChange={(e) =>
                                setFieldValue(
                                  'representativeName.firstName',
                                  e.target.value
                                )
                              }
                              label="Keresztnév"
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              value={values?.representativePhone}
                              disabled={readonly}
                              name="representativePhone"
                              onChange={(e) =>
                                setFieldValue(
                                  'representativePhone',
                                  e.target.value
                                )
                              }
                              label="Telefonszám"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  }
                />
              </Grid>
              {!values.isPrivatePerson && (
                <Grid item xs={12}>
                  <CollapsePaper
                    title={
                      <Typography
                        textAlign="left"
                        fontWeight={'bold'}
                        fontSize={20}
                        sx={{ mt: 2 }}
                      >
                        Cég információ{' '}
                      </Typography>
                    }
                    collapsed={false}
                    children={
                      <Grid container p={2}>
                        <Grid container item xs={12} spacing={2}>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={values.companyName ?? ''}
                              onChange={handleChange}
                              label="Cégnév"
                              required
                              fullWidth
                              name="companyName"
                              disabled={readonly}
                              error={
                                !!touched.companyName &&
                                !!validationErrors.companyName
                              }
                              helperText={
                                !!touched.companyName &&
                                !!(validationErrors.companyName as string) &&
                                (validationErrors.companyName as string)
                              }
                            />
                            {showDifference(
                              `${entity.companyName ?? ''}`,
                              `${values.companyName ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={values.bankName ?? ''}
                              onChange={handleChange}
                              label="Bank megnevezése"
                              name="bankName"
                              fullWidth
                              disabled={readonly}
                              error={
                                !!touched.bankName &&
                                !!validationErrors.bankName
                              }
                              helperText={
                                !!touched.bankName &&
                                !!(validationErrors.bankName as string) &&
                                (validationErrors.bankName as string)
                              }
                            />
                            {showDifference(
                              `${entity.bankName ?? ''}`,
                              `${values.bankName ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextField
                              value={values.bankAccountNumber ?? ''}
                              onChange={handleChange}
                              label="Bankszámlaszám"
                              name="bankAccountNumber"
                              fullWidth
                              disabled={readonly}
                              error={
                                !!touched.bankAccountNumber &&
                                !!validationErrors.bankAccountNumber
                              }
                              helperText={
                                !!touched.bankAccountNumber &&
                                !!(validationErrors.bankAccountNumber as string) &&
                                (validationErrors.bankAccountNumber as string)
                              }
                            />
                            {showDifference(
                              `${entity.bankAccountNumber ?? ''}`,
                              `${values.bankAccountNumber}`
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={values.iban ?? ''}
                              onChange={handleChange}
                              label="IBAN számlaszám"
                              name="iban"
                              fullWidth
                              disabled={readonly}
                              error={!!touched.iban && !!validationErrors.iban}
                              helperText={
                                !!touched.iban &&
                                !!(validationErrors.iban as string) &&
                                (validationErrors.iban as string)
                              }
                            />
                            {showDifference(
                              `${entity.iban ?? ''}`,
                              `${values.iban ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              value={values.swift ?? ''}
                              onChange={handleChange}
                              label="Swift / BIC kód"
                              name="swift"
                              fullWidth
                              disabled={readonly}
                              error={
                                !!touched.swift && !!validationErrors.swift
                              }
                              helperText={
                                !!touched.swift &&
                                !!(validationErrors.swift as string) &&
                                (validationErrors.swift as string)
                              }
                            />
                            {showDifference(
                              `${entity.swift ?? ''}`,
                              `${values.swift ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={values.companyRegistrationNumber ?? ''}
                              onChange={handleChange}
                              label="Cégjegyzékszám"
                              name="companyRegistrationNumber"
                              fullWidth
                              disabled={readonly}
                              error={
                                !!touched.companyRegistrationNumber &&
                                !!validationErrors.companyRegistrationNumber
                              }
                              helperText={
                                !!touched.companyRegistrationNumber &&
                                !!(validationErrors.companyRegistrationNumber as string) &&
                                (validationErrors.companyRegistrationNumber as string)
                              }
                            />
                            {showDifference(
                              `${entity.companyRegistrationNumber ?? ''}`,
                              `${values.companyRegistrationNumber ?? ''}`
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              value={values.comment ?? ''}
                              multiline
                              rows={5}
                              onChange={handleChange}
                              label="Megjegyzések"
                              name="comment"
                              fullWidth
                              disabled={readonly}
                              error={
                                !!touched.comment && !!validationErrors.comment
                              }
                              helperText={
                                !!touched.comment &&
                                !!(validationErrors.comment as string) &&
                                (validationErrors.comment as string)
                              }
                            />
                            {showDifference(
                              `${entity.comment ?? ''}`,
                              `${values.comment ?? ''}`
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <CollapsePaper
                  title={
                    <Typography
                      textAlign="left"
                      fontWeight={'bold'}
                      fontSize={20}
                      sx={{ mt: 2 }}
                    >
                      Adózás{' '}
                    </Typography>
                  }
                  collapsed={false}
                  children={
                    <Grid container p={2} justifyContent={'center'}>
                      <Grid item>
                        <Grid item>
                          <FormControl>
                            {!values.clientTaxType && (
                              <FormLabel>
                                <Typography color={'error'}>
                                  Kötelező mező!
                                </Typography>
                              </FormLabel>
                            )}
                            <FormLabel>Adózási típus</FormLabel>
                            <RadioGroup
                              defaultValue="female"
                              name="radio-buttons-group"
                              value={values.clientTaxType}
                              onChange={(e, value) => {
                                if (
                                  (parseInt(value) as ClientTaxType) !==
                                  ClientTaxType.HasTaxNumber
                                ) {
                                  setFieldValue('taxNumber', '');
                                  setFieldValue('groupMemberTaxNumber', '');
                                  setHasGroupTaxNumber(false);
                                }
                                setFieldValue(
                                  'clientTaxType',
                                  parseInt(value) as ClientTaxType
                                );
                              }}
                            >
                              {Object.values(ClientTaxType)
                                .filter((x) => Number.isFinite(x))
                                .map((x) => (
                                  <FormControlLabel
                                    value={x as ClientTaxType}
                                    control={<Radio />}
                                    label={translateClientTaxTypeName(
                                      x as ClientTaxType
                                    )}
                                  />
                                ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          pt={2}
                          container
                          alignItems={'center'}
                        >
                          <Grid item>
                            <TextField
                              value={values.taxNumber ?? ''}
                              onChange={handleChange}
                              label={
                                hasGroupTaxNumber
                                  ? 'Csoportazonosító '
                                  : 'Adószám / EU adószám'
                              }
                              name="taxNumber"
                              disabled={
                                readonly ||
                                values.clientTaxType ===
                                  ClientTaxType.HasNoTaxNumber ||
                                !values.clientTaxType
                              }
                              required={
                                values.clientTaxType !==
                                ClientTaxType.HasNoTaxNumber
                              }
                              error={
                                !!touched.taxNumber &&
                                !!validationErrors.taxNumber
                              }
                              helperText={
                                !!touched.taxNumber &&
                                !!(validationErrors.taxNumber as string) &&
                                (validationErrors.taxNumber as string)
                              }
                            />
                          </Grid>
                          {values.isTaxNumberInvalid && (
                            <Grid item>
                              <Tooltip title="Adószám érvénytelen">
                                <DangerousIcon color={'error'} />
                              </Tooltip>
                            </Grid>
                          )}
                          {!values.isTaxNumberInvalid && (
                            <Grid item>
                              <Tooltip title="Adószám érvényes">
                                <CheckCircleIcon color={'success'} />
                              </Tooltip>
                            </Grid>
                          )}
                          {showDifference(
                            `${entity.taxNumber ?? ''}`,
                            `${values.taxNumber}`
                          )}
                        </Grid>
                        {checkPermission(['QueryTaxPayer']) && (
                          <Grid item xs={12} pt={2}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                queryTaxPayerAddresses(
                                  values.taxNumber,
                                  true,
                                  setFieldValue,
                                  values
                                );
                              }}
                            >
                              Ellenőrzés
                            </Button>
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={<Checkbox />}
                              onChange={(event, checked) => {
                                setHasGroupTaxNumber(checked);
                                if (!checked) {
                                  setFieldValue('groupMemberTaxNumber', '');
                                }
                              }}
                              label="Csoportos adószám"
                              value={hasGroupTaxNumber}
                              disabled={
                                readonly ||
                                values.clientTaxType !==
                                  ClientTaxType.HasTaxNumber ||
                                !values.clientTaxType
                              }
                              checked={hasGroupTaxNumber ?? false}
                            />
                          </Grid>
                          {hasGroupTaxNumber && (
                            <Grid item xs={12}>
                              <TextField
                                value={values.groupMemberTaxNumber ?? ''}
                                onChange={handleChange}
                                label={'Csoport tag adószáma'}
                                name="groupMemberTaxNumber"
                                required={hasGroupTaxNumber}
                                disabled={
                                  readonly ||
                                  values.clientTaxType ===
                                    ClientTaxType.HasNoTaxNumber ||
                                  !values.clientTaxType
                                }
                                error={
                                  !!touched.groupMemberTaxNumber &&
                                  !!validationErrors.groupMemberTaxNumber
                                }
                                helperText={
                                  !!touched.groupMemberTaxNumber &&
                                  !!(validationErrors.groupMemberTaxNumber as string) &&
                                  (validationErrors.groupMemberTaxNumber as string)
                                }
                              />
                              {showDifference(
                                `${entity.groupMemberTaxNumber ?? ''}`,
                                `${values.groupMemberTaxNumber}`
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CollapsePaper
                  title={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={'space-between'}
                    >
                      <Grid item>
                        <Typography
                          textAlign="left"
                          fontWeight={'bold'}
                          fontSize={20}
                          sx={{ mt: 2 }}
                        >
                          Címek({values.addresses?.length ?? 0})
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  collapsed={true}
                  children={
                    <Grid container p={2}>
                      <Grid container justifyContent="left" spacing={2}>
                        {validationErrors.addresses &&
                          typeof validationErrors.addresses === 'string' && (
                            <Grid item xs={12}>
                              <Typography color="error">
                                {validationErrors.addresses as string}
                              </Typography>
                            </Grid>
                          )}
                        <Grid container xs={12} item spacing={2}>
                          {checkPermission(['QueryTaxPayer']) && (
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  queryTaxPayerAddresses(
                                    values.taxNumber,
                                    false,
                                    setFieldValue,
                                    values
                                  );
                                }}
                              >
                                Címek szinkronizálása
                              </Button>
                            </Grid>
                          )}
                          {checkPermission(['ClientCombineAddress']) && (
                            <Grid item>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setAddressCombineDialog(true);
                                }}
                              >
                                Címek összevonása
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <AddressesForm
                            values={values}
                            oldValues={entity}
                            setFieldValue={setFieldValue}
                            errors={validationErrors}
                            touched={touched}
                            readonly={readonly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CollapsePaper
                  title={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={'space-between'}
                    >
                      <Grid item>
                        <Typography
                          textAlign="left"
                          fontWeight={'bold'}
                          fontSize={20}
                          sx={{ mt: 2 }}
                        >
                          Beállítások
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  collapsed={true}
                  children={
                    <Grid container p={2}>
                      <Grid container justifyContent="left" spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.needElectronicInvoice}
                                onChange={(e, checked) =>
                                  setFieldValue(
                                    'needElectronicInvoice',
                                    checked
                                  )
                                }
                                color="primary"
                              />
                            }
                            label="E-számla kiállítása"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.isOfferMandatory}
                                onChange={(e, checked) =>
                                  setFieldValue('isOfferMandatory', checked)
                                }
                                color="primary"
                              />
                            }
                            label="Kötelező árajánlat kiállítása eladás elött."
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.hasSpecialNeeds}
                                onChange={(e, checked) =>
                                  setFieldValue('hasSpecialNeeds', checked)
                                }
                                color="primary"
                              />
                            }
                            label="Van különleges igény"
                          />
                        </Grid>
                        {values.hasSpecialNeeds && (
                          <Grid item xs={12}>
                            <TextField
                              value={values.specialNeeds ?? ''}
                              multiline
                              rows={5}
                              onChange={handleChange}
                              label="Különleges igények"
                              name="specialNeeds"
                              fullWidth
                              disabled={readonly}
                            />
                            {showDifference(
                              `${entity.specialNeeds ?? ''}`,
                              `${values.specialNeeds ?? ''}`
                            )}{' '}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Mentés
                    </Button>
                  </Grid>
                )}
                {!isDialog && (
                  <Grid item p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                )}
              </Grid>
              <SyncAddressesDialog
                open={syncAddressesDialogOpen.open}
                onClose={() => {
                  setSyncAddressesDialogOpen({ open: false, addresses: [] });
                }}
                onAgreed={(addresses: any) => {
                  setFieldValue('addresses', [
                    ...values.addresses,
                    ...addresses,
                  ]);
                }}
                addresses={syncAddressesDialogOpen.addresses}
              />
              <CombineAddressesDialog
                open={addressCombineDialog}
                onClose={() => {
                  setAddressCombineDialog(false);
                }}
                onAgreed={(addresses: any, addressToKeep: any) => {
                  debugger;
                  combineAddressHandler(addressToKeep, addresses);
                  setFieldValue('addresses', [
                    ...values.addresses.filter(
                      (x) => !addresses?.map((x) => x.id)?.includes(x.id)
                    ),
                  ]);
                }}
                addresses={values.addresses}
              />
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClientForm;
