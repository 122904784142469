import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  InputAdornment,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Form, Formik } from 'formik';
import CreateEntityDecorator from '../../../components/CreateEntityDecorator';
import CurrencyPicker from '../../../components/CurrencyPicker';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { ClientTypes } from '../../../types/ClientTypes';
import { Currency } from '../../../types/Currency';
import ExpenseTypeCreate from '../../erp/expenseTypes/ExpenseTypeCreate';
import { currencyAdornment } from '../../../utils/valueFormatters';

const ExpenseForm = (props: any) => {
  const {
    entity,
    onSubmit,
    errors,
    handleCancel,
    readonly = false,
    expenseTypes,
    onNewExpenseTypeAdded,
    isClosingSheet = false,
    clients,
    taxes,
    isDialog,
  } = props;

  const paymentTypes = ['Készpénz', 'Bankkártya', 'Átutalás', 'Utánvét'];
  const { checkPermission } = useCheckPermission();
  return (
    <Formik
      initialValues={entity}
      enableReinitialize={true}
      validate={(values) => {
        const errors: any = {};

        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
    >
      {({
        isSubmitting,
        values,
        touched,
        setFieldValue,
        handleChange,
        errors: validationErrors,
      }) => (
        <Form>
          {' '}
          <Paper>
            <Grid container justifyContent="left" spacing={2} p={5}>
              <h2>
                Kiadás{' '}
                {readonly
                  ? 'megtekintése'
                  : values.id > 0
                    ? 'szerkesztése'
                    : 'létrehozása'}
              </h2>
              <Grid xs={12} item pt={5}>
                <Box color="red">
                  {errors?.map((error) => <li>{error}</li>) ?? []}
                </Box>
              </Grid>
              {!isClosingSheet && (
                <>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <DesktopDatePicker
                        label="Dátum"
                        value={values.date}
                        inputFormat="yyyy-MM-dd"
                        disabled={readonly}
                        onChange={(value) => setFieldValue('date', value)}
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            {...params}
                            error={!!touched.date && !!validationErrors.date}
                            helperText={
                              !!touched.date &&
                              !!validationErrors.date &&
                              (validationErrors.date as string)
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <DesktopDatePicker
                        label="Fizetési Határidő"
                        value={values.paymentDeadline}
                        inputFormat="yyyy-MM-dd"
                        disabled={readonly}
                        onChange={(value) =>
                          setFieldValue('paymentDeadline', value)
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            fullWidth
                            {...params}
                            error={
                              !!touched.paymentDeadline &&
                              !!validationErrors.paymentDeadline
                            }
                            helperText={
                              !!touched.paymentDeadline &&
                              !!validationErrors.paymentDeadline &&
                              (validationErrors.paymentDeadline as string)
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="companyId"
                      disabled={readonly}
                      value={values?.companyId ?? ''}
                      onChange={(event, value) => {
                        setFieldValue('companyId', value);
                      }}
                      getOptionLabel={(option) =>
                        clients?.find((g) => g.id === option)?.companyName ?? ''
                      }
                      options={
                        clients
                          ?.filter(
                            (y) => y.clientType === ClientTypes.Distributor
                          )
                          ?.map((g) => g.id) ?? []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          error={
                            !!touched.companyId && !!validationErrors.companyId
                          }
                          helperText={
                            !!touched.companyId &&
                            !!validationErrors.companyId &&
                            (validationErrors.companyId as string)
                          }
                          fullWidth
                          label="Számlakibocsátó"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="clientId"
                      disabled={readonly}
                      value={values?.clientId ?? ''}
                      onChange={(event, value) => {
                        setFieldValue('clientId', value);
                      }}
                      getOptionLabel={(option) => {
                        let client = clients?.find((g) => g.id === option);
                        if (client) {
                          return `${client?.name.fullName ?? ''}(${
                            client?.companyName ?? ''
                          })`;
                        } else {
                          return '';
                        }
                      }}
                      options={
                        clients
                          ?.filter(
                            (y) => y.clientType !== ClientTypes.Distributor
                          )
                          ?.map((g) => g.id) ?? []
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={
                            !!touched.clientId && !!validationErrors.clientId
                          }
                          helperText={
                            !!touched.clientId &&
                            !!validationErrors.clientId &&
                            (validationErrors.clientId as string)
                          }
                          fullWidth
                          label="Ügyfél"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CurrencyPicker
                      currency={values.currency}
                      setCurrency={(value: Currency) => {
                        setFieldValue('currency', value);
                      }}
                      conversionRate={values.conversionRate}
                      readonly={readonly}
                      setConversionRate={(value: number) => {
                        setFieldValue('conversionRate', value);
                      }}
                      localStorageKey="ExpenseForm"
                      disableLanguage
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <CreateEntityDecorator
                  hideAdd={readonly || !checkPermission(['ExpenseTypeCreate'])}
                  AutocompleteComponent={
                    <Autocomplete
                      disablePortal
                      id="expenseType"
                      disabled={readonly}
                      value={values.expenseTypeId}
                      onChange={(event, value) => {
                        setFieldValue('expenseTypeId', value);
                      }}
                      getOptionLabel={(option) =>
                        expenseTypes?.find((g) => g.id === option)?.name ?? ''
                      }
                      options={expenseTypes?.map((g) => g.id) ?? []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          error={
                            !!touched.expenseTypeId &&
                            !!validationErrors.expenseTypeId
                          }
                          helperText={
                            !!touched.expenseTypeId &&
                            !!validationErrors.expenseTypeId &&
                            (validationErrors.expenseTypeId as string)
                          }
                          fullWidth
                          label="Kiadás típusa"
                        />
                      )}
                    />
                  }
                  onSave={(newExpenseType) => {
                    setFieldValue('expenseTypeId', newExpenseType.id);
                    onNewExpenseTypeAdded(newExpenseType);
                  }}
                  CreateEntityComponent={ExpenseTypeCreate}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.priceGross}
                  onChange={(e) => setFieldValue('priceGross', e.target.value)}
                  label="Ár"
                  name="priceGross"
                  required
                  disabled={readonly}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {' '}
                        {currencyAdornment(values.currency)}
                      </InputAdornment>
                    ),
                  }}
                  error={!!touched.priceGross && !!validationErrors.priceGross}
                  helperText={
                    !!touched.priceGross &&
                    !!validationErrors.priceGross &&
                    (validationErrors.priceGross as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  value={values.receiptNumber}
                  onChange={handleChange}
                  label="Bizonylatszám"
                  name="receiptNumber"
                  required={isClosingSheet}
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.receiptNumber && !!validationErrors.receiptNumber
                  }
                  helperText={
                    !!touched.receiptNumber &&
                    !!validationErrors.receiptNumber &&
                    (validationErrors.receiptNumber as string)
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="paymentType"
                  disabled={readonly}
                  value={values?.paymentType ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('paymentType', value);
                  }}
                  options={paymentTypes?.map((g) => g) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.paymentType && !!validationErrors.paymentType
                      }
                      helperText={
                        !!touched.paymentType &&
                        !!validationErrors.paymentType &&
                        (validationErrors.paymentType as string)
                      }
                      fullWidth
                      label="Fizetési mód"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="taxTypeId"
                  disabled={readonly}
                  value={values?.taxTypeId ?? ''}
                  onChange={(event, value) => {
                    setFieldValue('taxTypeId', value);
                  }}
                  getOptionLabel={(option) =>
                    taxes?.find((g) => g.id === option)?.name ?? ''
                  }
                  options={taxes?.map((g) => g.id) ?? []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      error={
                        !!touched.taxTypeId && !!validationErrors.taxTypeId
                      }
                      helperText={
                        !!touched.taxTypeId &&
                        !!validationErrors.taxTypeId &&
                        (validationErrors.taxTypeId as string)
                      }
                      fullWidth
                      label="ÁFA típusa"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  label="Kifizetve"
                  control={
                    <Checkbox
                      checked={values.isPaid}
                      disabled={readonly}
                      onChange={(e, checked) =>
                        setFieldValue('isPaid', checked)
                      }
                    />
                  }
                />
              </Grid>
              {values.isPaid && (
                <Grid item xs={12}>
                  <DesktopDatePicker
                    label="Kifizetés dátuma"
                    value={values.dateOfPayment}
                    inputFormat="yyyy-MM-dd"
                    disabled={readonly}
                    onChange={(value) => setFieldValue('dateOfPayment', value)}
                    renderInput={(params) => (
                      <TextField
                        required
                        fullWidth
                        {...params}
                        error={
                          !!touched.dateOfPayment &&
                          !!validationErrors.dateOfPayment
                        }
                        helperText={
                          !!touched.dateOfPayment &&
                          !!validationErrors.dateOfPayment &&
                          (validationErrors.dateOfPayment as string)
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  value={values.description}
                  onChange={handleChange}
                  label="Leírás"
                  name="description"
                  required
                  InputLabelProps={{ shrink: true }}
                  disabled={readonly}
                  fullWidth
                  error={
                    !!touched.description && !!validationErrors.description
                  }
                  helperText={
                    !!touched.description &&
                    !!validationErrors.description &&
                    (validationErrors.description as string)
                  }
                />
              </Grid>
              <Grid container item pt={3} justifyContent="left">
                {!readonly && (
                  <Grid item p={1}>
                    <Button type="submit" variant="contained" color="primary">
                      Mentés
                    </Button>
                  </Grid>
                )}
                {!isDialog && (
                  <Grid item p={1}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Mégse
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default ExpenseForm;
