import axios from 'axios';
import { postData } from '../axiosUtils';

const chimneyControlAppointmentService = {
  list: (
    startDate: any | null = null,
    endDate: any | null = null,
    status: any | null = null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/chimneyControlAppointment/list`;
    if (startDate) {
      url += `?startDate=${startDate?.toISOString()}`;
    }
    if (endDate && !startDate) {
      url += `?endDate=${endDate?.toISOString()}`;
    } else if (endDate) {
      url += `&endDate=${endDate?.toISOString()}`;
    }
    if (status && !startDate && !endDate) {
      url += `?status=${status}`;
    } else if (status) {
      url += `&status=${status}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/chimneyControlAppointment/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/chimneyControlAppointment/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/chimneyControlAppointment/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/chimneyControlAppointment/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default chimneyControlAppointmentService;
