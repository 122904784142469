import {
  Add as AddIcon,
  Cancel,
  CreditCard,
  LocalShipping,
  Payments,
  RequestQuote,
} from '@mui/icons-material';
import { Button, Dialog, DialogActions, Grid, Tooltip } from '@mui/material';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { parseJSON } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DataList from '../../../components/DataList';
import EntityNavigator from '../../../components/EntityNavigator';
import '../../../css/dataGrid.css';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import { ChimneySweepingCertificateStatus } from '../../../types/ChimneySweepingCertificateStatus';
import { EntityTypes } from '../../../types/EntityTypes';
import { formatCurrency } from '../../../utils/valueFormatters';

const MissingChimneySweepingCertificateDialog = (props: any) => {
  const { open, setOpen, serialNumberedBlockId } = props;
  const navigate = useNavigate();
  const [missingCerts, setMissingCerts] = useState<any[]>([]);
  const [now, setNow] = useState<Date>(new Date());

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();

  const fetchMissingCers = (rows: any) => {
    if (!rows) {
      return;
    }
    let documentNumbers = rows.map((row) => parseInt(row.documentNumber));
    let smallest = Math.min(...documentNumbers);
    let largest = Math.max(...documentNumbers);
    const allNumbers = Array.from(
      { length: largest - smallest + 1 },
      (_, index) => index + smallest
    );
    const presentNumbers = new Set(documentNumbers);
    const missingNumbers = allNumbers.filter((num) => !presentNumbers.has(num));
    setMissingCerts(
      missingNumbers.map((num) => {
        return {
          name: 'ÉGÉSTERMÉK-ELVEZETŐK ELLENŐRZÉSE, SZÜKSÉG SZERINTI TISZTÍTÁSA, 4 ÉVENKÉNTI MŰSZAKI FELÜLVIZSGÁLATA',
          id: num,
          isNotRealCert: true,
          airSpaceConnectionAmount: '',
          clientId: null,
          employeeId: null,
          serviceProviderId: null,
          approverName: '',
          documentNumber: num.toString(),
          approverTitle: '',
          others: '',
          date: now,
          workAddressId: null,
          carbonMonoxideSensors: [],
          obligedToInstallCODevice: '',
          obligedToInstallCODevicePlaceNumber: '',
          hasCODevice: '',
          cODevicePlace: '',
          cODeviceWorks: '',
          cleaningDone: '',
          calibrationDone: '',
          hasDocuments: '',
          expired: '',
          errorMessage: '',
          appearedOnLocation: true,
          inspection: true,
          cleaning: false,
          technicalReview: false,
          chimneyControls: [],
          hasDeliveryFee: true,
          isPaid: true,
          paymentMethod: 'Készpénz',
          status: ChimneySweepingCertificateStatus.Cancelled,
        };
      })
    );
  };
  useEffect(() => {
    if (!(serialNumberedBlockId > 0)) {
      return;
    }
    let abort = new AbortController();
    dispatch({ type: 'SHOW_QUERY' });
    chimneySweepingCertificateService
      .list(null, null, null, abort.signal, null, serialNumberedBlockId)
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) {
          fetchMissingCers(response.records);
        } else {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));

    return () => {
      abort.abort();
    };
  }, [serialNumberedBlockId]);

  const createStornoCert = (entity: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    return chimneySweepingCertificateService
      .create({ ...entity, id: 0 })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });
          setMissingCerts((prev) => {
            return prev.filter((cert) => cert.id !== entity.id);
          });
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
      });
  };

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title={'Tanúsítvány létrehozása'}>
            <AddIcon color="primary" />
          </Tooltip>
        }
        label={'Tanúsítvány létrehozása'}
        onClick={() => {
          navigate(
            `/erp/chimneySweepingCertificates/create/${params.row.documentNumber}`
          );
        }}
      />
    );
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          <Tooltip title={'Sztornózott tanúsítvány'}>
            <Cancel color="error" />
          </Tooltip>
        }
        label={'Sztornózott tanúsítvány'}
        onClick={() => {
          createStornoCert(params.row);
        }}
      />
    );

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'paymentMethod',
      headerName: '',
      flex: 20,
      renderCell(params) {
        if (params.row.paymentMethod === 'Készpénz') {
          return (
            <Tooltip title="Készpénz">
              <Payments color="success" />
            </Tooltip>
          );
        } else if (params.row.paymentMethod === 'Átutalás') {
          return (
            <Tooltip
              title={`Átutalás(${
                params.row.isInvoicePaid ? 'Fizetve' : 'Nem fizetve'
              })`}
            >
              <RequestQuote
                color={params.row.isInvoicePaid ? 'success' : 'primary'}
              />
            </Tooltip>
          );
        } else if (params.row.paymentMethod === 'Bankkártya') {
          return (
            <Tooltip title="Bankkártya">
              <CreditCard color="success" />
            </Tooltip>
          );
        } else if (params.row.paymentMethod === 'Utánvét') {
          return (
            <Tooltip title="Utánvét">
              <LocalShipping color="primary" />
            </Tooltip>
          );
        }
      },
    },
    {
      field: 'date',
      headerName: 'Dátum',
      flex: 150,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },
    {
      field: 'documentNumber',
      headerName: 'Dokumentum száma',
      flex: 100,
      valueGetter(params) {
        return params.value ? parseInt(params.value) : params.value;
      },
      valueFormatter(params) {
        return params.value ? params.value : 'Nincs';
      },
    },
    {
      field: 'employee',
      headerName: 'Dolgozó',
      flex: 100,
      valueFormatter(params) {
        return params.value ? params.value.fullName : 'Nincs';
      },
    },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 200,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    {
      field: 'clientPhone',
      headerName: 'Elérhetőség',
      flex: 150,
      valueGetter(params) {
        return (
          (params.row.clientPhone ?? '') +
          (params.row.clientPhone ? ';' : '') +
          (params.row.workAddressEmail ?? '')
        );
      },
    },
    { field: 'workAddress', headerName: 'Cím', flex: 150 },
    { field: 'invoiceNumber', headerName: 'Számlaszám', flex: 150 },
    {
      field: 'finalPriceGross',
      headerName: 'Végösszeg(Bruttó)',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? formatCurrency(params.value?.toFixed(0))
          : formatCurrency(0);
      },
    },

    {
      field: 'actions',
      type: 'actions',
      flex: 300,
      align: 'right',
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleClose = () => {
    setOpen(false);
    setMissingCerts([]);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'xl'}>
      <Grid container p={3}>
        <Grid item xs={12}>
          <DataList
            rows={missingCerts}
            columns={columns}
            localStorageKey={'MissingChimneySweepingCertificateDialog'}
            getRowId={(row) => row.id}
            minimal
            getRowClassName={(params) => {
              return params.row.status ===
                ChimneySweepingCertificateStatus.Cancelled
                ? 'failed'
                : '';
            }}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={handleClose} variant="contained">
          Kilépés
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MissingChimneySweepingCertificateDialog;
