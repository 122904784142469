import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import ClientForm from './ClientForm';
import { useNavigate } from 'react-router-dom';
import clientService from '../../../services/crm/clientService';
import { ClientTypes } from '../../../types/ClientTypes';
import useCheckPermission from '../../../hooks/useCheckPermission';
import { AddressTypes } from '../../../types/AddressTypes';
import { ClientTaxType } from '../../../types/ClientTaxType';
import { ApiKeyType } from '../../../types/ApiKeyType';

const ClientCreate = (props: any) => {
  const {
    onSave = null,
    navigateBack = true,
    handleCancel = null,
    taxNumber = null,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const { checkPermission } = useCheckPermission();

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    clientService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés', {
            variant: 'success',
          });
          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <ClientForm
      externalTaxNumber={taxNumber}
      readonly={!checkPermission(['ClientCreateDraft'])}
      entity={{
        name: {
          firstName: '',
          lastName: '',
          middleName: '',
          title: '',
        },
        clientType: ClientTypes.Customer,
        companyName: '',
        customerLedgerNumber: '',
        bankName: '',
        bankAccountNumber: '',
        szamlazzhuApi: '',
        iban: '',
        swift: '',
        clientTaxType: ClientTaxType.HasTaxNumber,
        contact: {
          phone: '',
          webAddress: '',
          email: '',
          contactName: '',
          address: {
            postalCode: 0,
            type: AddressTypes.Headquarters,
            city: '',
            street: '',
            houseNumber: '',
            country: '',
            district: '',
            comment: '',
            hasPerson: false,
            personName: {
              firstName: '',
              lastName: '',
              middleName: '',
              title: '',
            },
            personPhone: '',
            personEmail: '',
          },
        },
        representativeName: { firstName: '', lastName: '' },
        representativePhone: '',
        addresses: [],
        isPrivatePerson: true,
        taxNumber: taxNumber !== null ? taxNumber : '',
        comment: '',
        apiKeyDescription: '',
        apiKey: '',
        hasApiKey: false,
        apiKeyName: '',
        apiKeyType: ApiKeyType.Szamlazzhu,
        hasSpecialNeeds: false,
        specialNeeds: '',
        companyRegistrationNumber: '',
        needElectronicInvoice: false,
        isOfferMandatory: false,
      }}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default ClientCreate;
