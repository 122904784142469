import { Grid, Typography, Button } from '@mui/material';
import { ContractTypeScopes } from '../../../types/ContractTypeScopes';
import { useEffect } from 'react';

const ContractTypeScopePicker = (props: any) => {
  const { values, setFieldValue, readonly } = props;

  useEffect(() => {
    console.log('ContractTypeScopePicker', values.scope);
  }, [values.scope]);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12}>
        <Typography fontWeight={'bold'}>Hatókör:</Typography>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          disabled={readonly}
          variant={
            (values.scope & ContractTypeScopes.ChimneySweepingCertificate) > 0
              ? 'contained'
              : 'outlined'
          }
          onClick={() => {
            setFieldValue(
              'scope',
              values.scope ^ ContractTypeScopes.ChimneySweepingCertificate
            );
          }}
        >
          Kéményseprői tanúsítvány
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          disabled={readonly}
          variant={
            (values.scope & ContractTypeScopes.WorkItem) > 0
              ? 'contained'
              : 'outlined'
          }
          onClick={() => {
            setFieldValue('scope', values.scope ^ ContractTypeScopes.WorkItem);
          }}
        >
          Munkalap
        </Button>
      </Grid>
      <Grid item>
        <Button
          color="primary"
          disabled={readonly}
          variant={
            (values.scope & ContractTypeScopes.Sale) > 0
              ? 'contained'
              : 'outlined'
          }
          onClick={() => {
            setFieldValue('scope', values.scope ^ ContractTypeScopes.Sale);
          }}
        >
          Eladás
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContractTypeScopePicker;
