import { GetApp } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Pagination,
  TextField,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import {
  DataGrid,
  GridFilterModel,
  GridFilterOperator,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
} from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import { GRID_HUNGARIAN_LOCALE_TEXT } from '../localization/mui/dataGrid/localeTextConstants';
import DataCard from './DataCard';

const CustomToolbar = ({
  minimal,
  csvOptions,
  printOptions,
  excelExportOptions,
  exportToExcel,
  rows,
}: any) => {
  return (
    <GridToolbarContainer>
      <Grid container justifyContent="space-between">
        <Grid item>
          <GridToolbarQuickFilter />
        </Grid>
        {!minimal && (
          <Grid item>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <GridToolbarExport
              csvOptions={csvOptions}
              printOptions={printOptions}
            />
            {!excelExportOptions.disabled && (
              <Tooltip title="Exportálás excelbe">
                <IconButton color="primary" onClick={() => exportToExcel(rows)}>
                  <GetApp />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}
      </Grid>
    </GridToolbarContainer>
  );
};

const DataList = (props: any) => {
  const isNotSmallScreen = useMediaQuery('(min-width:800px)');

  const {
    columns,
    rows,
    getRowId = (row: any) => row.id,
    initialState = {},
    processRowUpdate = (row: any) => row,
    exportFields,
    minimal = false,
    height = '60vh',
    excelExportOptions = { fileName: 'export', disabled: false },
    localStorageKey = '',
    disableSearchBar = false,
    exportToExcel = (data) => {
      internalExportToExcel(data);
    },
    ...rest
  } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const filteredRows = useMemo(() => {
    if (!searchQuery) return rows;
    return rows.filter((row: any) =>
      columns.some((column: any) =>
        row[column.field]
          ?.toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    );
  }, [rows, columns, searchQuery]);

  const [pageSize, setPageSize] = useState(100);
  const cardsPerPage = 4;
  const totalCards = filteredRows.length;
  const totalPages = Math.ceil(totalCards / cardsPerPage);
  const apiRef = useGridApiRef();

  const [cardPage, setCardPage] = useState<number>(1);

  const startIndex = (cardPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;

  useEffect(() => {
    const filterModelLocal = localStorage.getItem(
      `filterModelLocal_${window.location.hash}_${localStorageKey}`
    );
    const columnVisibilityLocal = localStorage.getItem(
      `columnVisibilityLocal_${window.location.hash}_${localStorageKey}`
    );
    const pageLocal = localStorage.getItem(
      `pageLocal_${window.location.hash}_${localStorageKey}`
    );
    const sortModelLocal = localStorage.getItem(
      `sortModelLocal_${window.location.hash}_${localStorageKey}`
    );
    if (
      filterModelLocal &&
      apiRef.current &&
      Object.keys(apiRef.current).length > 0
    ) {
      let parsedFilterModelLocal = JSON.parse(filterModelLocal);
      apiRef.current.setFilterModel(
        parsedFilterModelLocal as GridFilterModel,
        'restoreState'
      );
    }
    if (
      columnVisibilityLocal &&
      apiRef.current &&
      Object.keys(apiRef.current).length > 0
    ) {
      let parsedColumnVisibilityLocal = JSON.parse(columnVisibilityLocal);
      apiRef.current.setColumnVisibilityModel(parsedColumnVisibilityLocal);
    }
    if (
      sortModelLocal &&
      apiRef.current &&
      Object.keys(apiRef.current).length > 0
    ) {
      let parsedSortModelLocal = JSON.parse(sortModelLocal);
      apiRef.current.setSortModel(parsedSortModelLocal);
    }
    if (pageLocal && apiRef.current && Object.keys(apiRef.current).length > 0) {
      let parsedPageLocal = parseInt(pageLocal);
      apiRef.current.setPage(parsedPageLocal);
    }
  }, [apiRef]);

  function convertObjectsToArrays(objects: any[]) {
    if (!Array.isArray(objects)) {
      throw new Error('Input must be an array of objects');
    }

    if (objects.length === 0) {
      return [];
    }

    const keys = Object.keys(objects[0]).filter(
      (x) => typeof objects[0][x] !== 'object'
    );

    const headers = keys.map((key) => key.toUpperCase());
    const rows = objects.map((object) => {
      if (typeof object !== 'object') {
        throw new Error('Elements of the input array must be objects');
      }

      return keys.map((key) => {
        const value = object[key];
        if (
          typeof value === 'string' &&
          value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}Z$/)
        ) {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return value;
          }
          return date.toLocaleString();
        }

        return value;
      });
    });

    return [headers, ...rows];
  }

  function internalExportToExcel(data: any) {
    var array = convertObjectsToArrays(data);
    const ws = XLSX.utils.aoa_to_sheet(array);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Adatsor');
    XLSX.writeFile(
      wb,
      excelExportOptions.fileName + '_' + new Date().toLocaleString() + '.xlsx'
    );
  }

  const csvOptions = {
    header: true,
    delimiter: ';',
    fileName: `export_${new Date().toLocaleString()}`,
    columnDelimiter: ';',
    columnSeparator: ';',
    fileExtension: 'csv',
    decimalSeparator: '.',
    showTitle: true,
    title: `${new Date().toLocaleString()}`,
    utf8WithBom: true,
  };

  const printOptions = {
    title: `export_${new Date().toLocaleString()}`,
    fileName: `export_${new Date().toLocaleString()}`,
    landscape: true,
    width: '100%',
    height: '100%',
    printBackground: false,
    scale: 1,
    hideToolbar: true,
    useCss: true,
    hideFooter: true,
    fields: exportFields,
  };

  return (
    <Box
      style={{
        height: isNotSmallScreen ? height : '',
        width: '100%',
      }}
    >
      {isNotSmallScreen ? (
        <>
          <DataGrid
            density={'compact'}
            apiRef={apiRef}
            localeText={GRID_HUNGARIAN_LOCALE_TEXT}
            experimentalFeatures={{ newEditingApi: true }}
            rows={rows}
            initialState={{
              ...initialState,
              columns: {
                columnVisibilityModel: {
                  id: false,
                  clientNameForQuickSearch: false,
                  ...initialState.columns?.columnVisibilityModel,
                },
              },
            }}
            columns={columns}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            editMode="row"
            pagination={true}
            pageSize={pageSize}
            slots={{ toolbar: CustomToolbar }}
            processRowUpdate={processRowUpdate}
            getRowId={getRowId}
            onPaginationModelChange={(model) => {
              localStorage.setItem(
                `pageLocal_${window.location.hash}_${localStorageKey}`,
                JSON.stringify(model.page)
              );
              setPageSize(model.pageSize);
            }}
            onFilterModelChange={(model) =>
              localStorage.setItem(
                `filterModelLocal_${window.location.hash}_${localStorageKey}`,
                JSON.stringify(model)
              )
            }
            onColumnVisibilityModelChange={(model) => {
              localStorage.setItem(
                `columnVisibilityLocal_${window.location.hash}_${localStorageKey}`,
                JSON.stringify(model)
              );
            }}
            onSortModelChange={(model) =>
              localStorage.setItem(
                `sortModelLocal_${window.location.hash}_${localStorageKey}`,
                JSON.stringify(model)
              )
            }
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 5000 },
                quickFilterExcludeHiddenColumns: false,
                minimal,
                csvOptions,
                printOptions,
                excelExportOptions,
                exportToExcel,
                rows,
              },
            }}
            {...rest}
          />
        </>
      ) : (
        <Grid>
          <Grid
            container
            spacing={2}
            paddingBottom={2}
            justifyContent={'flex-end'}
          >
            {!disableSearchBar && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder="Keresés..."
                  value={searchQuery}
                  onChange={(e: any) => setSearchQuery(e.target.value)}
                />
              </Grid>
            )}
            {filteredRows.length > 0 && (
              <Grid item>
                <IconButton
                  color="primary"
                  onClick={() => exportToExcel(filteredRows)}
                >
                  <GetApp />
                </IconButton>
              </Grid>
            )}
            {filteredRows.slice(startIndex, endIndex).map((row) => (
              <Grid item xs={12} key={row.id}>
                <DataCard row={row} headers={columns} {...props} />
              </Grid>
            ))}
            {filteredRows.length === 0 && (
              <Box padding={2} textAlign="center">
                Nincs megjeleníthető adat
              </Box>
            )}
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Pagination
                count={totalPages}
                page={cardPage}
                onChange={(_event, value) => setCardPage(value)}
                color="primary"
                size="small"
                siblingCount={0}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default DataList;
