import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ChimneySweepingCertOrderType } from '../types/ChimneySweepingCertOrderType';
import { translateChimneySweepingCertOrderType } from '../utils/nameFormatters';

export const ChimneySweepingCertificateOrderTypePicker = (props: any) => {
  const { value, setValue } = props;
  return (
    <Grid item xs={12}>
      <FormControl>
        <FormLabel>Megrendelés típusa</FormLabel>
        <RadioGroup
          row
          value={value}
          onChange={(e, value) => {
            setValue(parseInt(value) as ChimneySweepingCertOrderType);
          }}
        >
          {(
            Object.values(ChimneySweepingCertOrderType).filter((x) =>
              Number.isFinite(x)
            ) ?? []
          ).map((x) => (
            <FormControlLabel
              value={x}
              control={<Radio />}
              label={translateChimneySweepingCertOrderType(
                x as ChimneySweepingCertOrderType
              )}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};
