import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Grid, Menu, MenuItem, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import * as React from 'react';
import { useSelector } from 'react-redux';
import DotIcon from '@mui/icons-material/FiberManualRecord';
import useCheckPermission from '../hooks/useCheckPermission';
import useSignalREffect from '../hooks/useSignalREffect';
import { useEffect, useCallback } from 'react';
import appService from '../services/appService';
import taskService from '../services/erp/taskService';
import { useSnackbar } from 'notistack';
import { MenuBook } from '@mui/icons-material';

const HasNotificationIcon = () => {
  return (
    <span style={{ color: 'white' }}>
      <NotificationsIcon color="inherit" />
      <DotIcon
        style={{ margin: '-10px -10px 5px -15px' }}
        fontSize="small"
        color="error"
      />
    </span>
  );
};

const NotificationsBell = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const user = useSelector((state: any) => state.user.userInfo);
  const { pendingTasks } = props;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const approvalCount = useSelector(
    (state: any) => state.app.pendingItemsApprovalCount
  );
  const { checkPermission } = useCheckPermission();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <IconButton color="inherit" name="notifications" onClick={handleClick}>
        {pendingTasks.length > 0 ||
        (checkPermission(['ItemApprove']) && approvalCount > 0) ? (
          <HasNotificationIcon />
        ) : (
          <NotificationsIcon />
        )}
      </IconButton>

      <Menu
        id="notifications"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 64,
              height: 64,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 95,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
      >
        {checkPermission(['ItemApprove']) && approvalCount > 0 && (
          <MenuItem
            to="/wms/items/approval"
            component={Link}
            onClick={handleClose}
            sx={{ borderBottom: '1px solid #ccc' }}
          >
            {approvalCount} termék vár jóváhagyásra
          </MenuItem>
        )}
        {checkPermission(['TaskView']) &&
          pendingTasks.length > 0 &&
          pendingTasks
            .sort(
              (a: any, b: any) =>
                (new Date(b.date) as any) - (new Date(a.date) as any)
            )
            .map((task: any) => (
              <MenuItem
                to={`/erp/tasks/edit/${task.id}`}
                component={Link}
                onClick={handleClose}
                key={task.id}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  sx={{ borderBottom: '1px solid #ccc' }}
                  maxWidth={350}
                >
                  <Grid item xs={12}>
                    <Grid item container xs={12}>
                      <Typography
                        color="textFirst"
                        variant="body2"
                        paddingRight={1}
                      >
                        Új feladat!
                      </Typography>
                      <Typography color="textSecondary" variant="caption">
                        {new Date(task.date).toLocaleDateString()}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} textAlign={'left'}>
                    <Typography color="textSecondary" variant="caption">
                      {task.description.substring(0, 57)}
                    </Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            ))}
        {pendingTasks.length === 0 &&
          (checkPermission(['ItemApprove']) ? approvalCount === 0 : true) && (
            <MenuItem onClick={handleClose}>Nincs értesítés</MenuItem>
          )}
      </Menu>
    </>
  );
};

export default NotificationsBell;
