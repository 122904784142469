import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import {
  Paper,
  IconButton,
  Grid,
  Tooltip,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
  Add,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import chimneyControlAppointmentService from '../../../services/chimneySweeping/chimneyControlAppointmentService';
import {
  translateChimneyControlAppointmentStatusesName,
  translateDamageScaleName,
} from '../../../utils/nameFormatters';
import { ChimneyControlAppointmentStatuses } from '../../../types/ChimneyControlAppointmentStatuses';
import ChimneyControlAppointmentDialog from './ChimneyControlAppointmentDialog';
import {
  set,
  parseJSON,
  endOfMonth,
  startOfMonth,
  eachMonthOfInterval,
  eachDayOfInterval,
} from 'date-fns';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import * as XLSX from 'xlsx';
import DatePickerHeader from '../../../components/DatePickerHeader';
import moment from 'moment';
import chimneySweepingCertificateService from '../../../services/chimneySweeping/chimneySweepingCertificateService';
import { DamageScale } from '../../../types/DamageScale';

const ChimneyControlAppointmentPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const userId = useSelector((state: any) => state.user.userInfo.userId);
  const dispatch = useDispatch<any>();
  const [status, setStatus] = useState<any>(
    ChimneyControlAppointmentStatuses.Planned
  );
  const [now, setNow] = useState<Date>(new Date());

  const [selectedInterval, setSelectedInterval] = useState<any>({
    startDate: startOfMonth(moment(now).startOf('day').toDate()),
    endDate: endOfMonth(moment(now).endOf('day').toDate()),
  });
  const [tabValue, setTabValue] = useState<number>(2);

  const [chimneyControlAppointmentDialog, setChimneyControlAppointmentDialog] =
    useState<any>({
      open: false,
      entity: {},
      chimneySweepingCertificateId: 0,
      readonly: false,
      hasClientSpecialNeeds: false,
      specialNeeds: '',
      isOfferMandatory: false,
    });
  const titleDescriptor = useFunctionDescriptor(
    'ChimneyControlAppointmentPage.title'
  );

  const { checkPermission } = useCheckPermission();

  const fetchData = async (abort?: AbortController) => {
    const { startDate, endDate } = selectedInterval;

    // Parse the startDate and endDate to Date objects
    let start = new Date(startDate);
    let end = new Date(endDate);
    // Helper function to perform the query based on the list type
    const performQuery = async (start, end) => {
      return await chimneyControlAppointmentService.list(
        start,
        end,
        status,
        abort?.signal
      );
    };

    try {
      if (!endDate) {
        end = new Date();
      }
      let months = eachMonthOfInterval({ start, end });
      if (eachDayOfInterval({ start, end }).length < 28) {
        months = [start];
      }
      let allRecords = [];

      for (let i = months.length - 1; i >= 0; i--) {
        let isSameStartMonth =
          months[i].getMonth() === start.getMonth() &&
          months[i].getFullYear() === start.getFullYear();
        let isSameEndMonth =
          months[i].getMonth() === end.getMonth() &&
          months[i].getFullYear() === end.getFullYear();
        const monthStart =
          1 === months.length ? start : isSameStartMonth ? start : months[i];
        const monthEnd =
          i === months.length - 1
            ? end
            : isSameEndMonth
              ? end
              : endOfMonth(monthStart);
        const formattedStart = monthStart;
        const formattedEnd = monthEnd;
        if (i === months.length - 1) {
          dispatch({
            type: 'SHOW_QUERY',
            payload: { abort: abort },
          });
        }
        const response = await performQuery(formattedStart, formattedEnd);

        if (response.canceled) {
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        if (response.hasError) {
          console.error('Query had errors:', response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
          if (i === months.length - 1) {
            dispatch({ type: 'HIDE' });
          }
          return;
        }

        allRecords = allRecords.concat(response.records);
        setRows(allRecords);
        if (i === months.length - 1) {
          dispatch({ type: 'HIDE' });
        }
      }
    } catch (error) {
      console.error('An error occurred while fetching data:', error);
      enqueueSnackbar('An error occurred while fetching data.', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    let abort = new AbortController();
    fetchData(abort);

    return () => {
      abort.abort();
    };
  }, [selectedInterval, status]);

  function convertObjectsToArrays(objects: any[]) {
    if (!Array.isArray(objects)) {
      throw new Error('Input must be an array of objects');
    }

    if (objects?.length === 0) {
      return [];
    }

    const translationMap = {
      appointmentDate: 'Egyeztetett időpont',
      createdByName: 'Egyeztető',
      employeeName: 'Kéményseprő',
      clientName: 'Ügyfél',
      clientPhone: 'Telefonszám',
      address: 'Cím',
      description: 'Leírás',
      clientSpecialNeeds: 'Ügyfél különleges igényei',
      isOfferMandatory: 'Kötelező árajánlat',
    };

    const filteredKeys = [
      'appointmentDate',
      'createdByName',
      'employeeName',
      'clientName',
      'clientPhone',
      'address',
      'description',
      'clientSpecialNeeds',
      'isOfferMandatory',
    ];
    const headers = filteredKeys.map((key) =>
      translationMap[key]?.toUpperCase()
    );
    const rows = objects.map((object) => {
      if (typeof object !== 'object') {
        throw new Error('Elements of the input array must be objects');
      }

      return filteredKeys.map((key) => {
        const value = object[key];

        if (new Date(value).toString() !== 'Invalid Date') {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return parseJSON(value).toLocaleDateString();
          }
          return parseJSON(value).toLocaleDateString();
        }
        if (typeof value === 'boolean') {
          return value ? 'Igen' : 'Nem';
        }
        return value;
      });
    });

    return [headers, ...rows];
  }
  function exportToExcel(data: any) {
    var array = convertObjectsToArrays(data);
    const ws = XLSX.utils.aoa_to_sheet(array);

    // Calculate column widths based on content length and header length
    const columnWidths = array[0].map((header, columnIndex) => {
      const columnValues = array.map((row) => String(row[columnIndex]));
      const headerLength = header?.length;
      const maxContentLength = Math.max(
        headerLength,
        ...columnValues.map((value) => value?.length)
      );
      return maxContentLength + 2; // Add extra space for readability
    });

    // Set column widths
    ws['!cols'] = columnWidths.map((width) => ({ width }));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Adatsor');
    const fileName = `Egyeztetett kémény ellenőrzések.xlsx`;
    XLSX.writeFile(wb, fileName);
  }

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly =
      !checkPermission(['ChimneyControlAppointmentEdit']) ||
      params.row.createdById !== userId;

    if (params.row.status === ChimneyControlAppointmentStatuses.Planned) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Tanúsítvány létrehozása">
              <Add />
            </Tooltip>
          }
          label={'Tanúsítvány létrehozása'}
          onClick={() => {
            navigate(
              `/erp/chimneySweepingCertificates/createFromAppointment/${params.row.id}`
            );
          }}
        />
      );
    }
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() =>
          setChimneyControlAppointmentDialog({
            open: true,
            entity: params.row,
            chimneySweepingCertificateId:
              params.row.chimneySweepingCertificateId,
            readonly: readonly,
            hasClientSpecialNeeds: params.row.hasClientSpecialNeeds,
            specialNeeds: params.row.clientSpecialNeeds,
            isOfferMandatory: params.row.isOfferMandatory,
          })
        }
      />
    );

    if (checkPermission(['ChimneyControlAppointmentDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'appointmentDate',
      headerName: 'Egyeztetett időpont',
      flex: 150,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleDateString()
          : 'Nincs';
      },
    },
    {
      field: 'appointmentTimeStart',
      headerName: '-Tól',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })
          : 'Nincs';
      },
    },
    {
      field: 'appointmentTimeEnd',
      headerName: '-Ig',
      flex: 100,
      valueFormatter(params) {
        return params.value
          ? parseJSON(params.value).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })
          : 'Nincs';
      },
    },
    { field: 'createdByName', headerName: 'Egyeztető', flex: 150 },
    { field: 'employeeName', headerName: 'Kéményseprő', flex: 150 },

    {
      field: 'clientName',

      headerName: 'Ügyfél',
      flex: 150,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    { field: 'clientPhone', headerName: 'Telefonszám', flex: 100 },
    { field: 'address', headerName: 'Cím', flex: 300 },
    { field: 'description', headerName: 'Leírás', flex: 200 },
    {
      field: 'isOfferMandatory',
      headerName: 'Kötelező árajánlat',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'hasClientSpecialNeeds',
      headerName: 'Van különleges ügyfgél igény?',
      flex: 100,
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
    },
    {
      field: 'status',
      headerName: 'státusz',
      flex: 150,
      renderCell(params) {
        let color =
          params.value === ChimneyControlAppointmentStatuses.Completed
            ? 'green'
            : params.value === ChimneyControlAppointmentStatuses.Planned
              ? 'lightgreen'
              : params.value === ChimneyControlAppointmentStatuses.Failed
                ? 'red'
                : 'orange';
        return (
          <div style={{ color: color }}>
            {translateChimneyControlAppointmentStatusesName(
              params.value as ChimneyControlAppointmentStatuses
            )}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 150,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    chimneyControlAppointmentService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Egyeztetett kémény ellenőrzések {titleDescriptor}</h2>
        </Grid>
        <Grid item xs={12}>
          <DatePickerHeader
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
            tabValue={tabValue}
            setTabValue={setTabValue}
            netGrossPicker={false}
            localStorageKey={'ChimneyControlAppointmentPage'}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Autocomplete
            disablePortal
            value={status}
            onChange={(event, value) => {
              setStatus(value);
            }}
            getOptionLabel={(option) => {
              return translateChimneyControlAppointmentStatusesName(
                option as ChimneyControlAppointmentStatuses
              );
            }}
            options={[
              null,
              ...(Object.values(ChimneyControlAppointmentStatuses).filter((x) =>
                Number.isFinite(x)
              ) ?? []),
            ]}
            renderInput={(params) => <TextField {...params} label="Szűrő" />}
          />
        </Grid>
        <Grid container item xs={12} p={2} justifyContent="end" spacing={2}>
          {checkPermission(['ChimneyControlAppointmentCreate']) && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setChimneyControlAppointmentDialog({
                    open: true,
                  });
                }}
              >
                Egyeztetés létrehozása
              </Button>
            </Grid>
          )}
          {checkPermission(['ChimneySweepingCertificateCreate']) && (
            <Grid item>
              <Button
                component={RouterLink}
                to={`/erp/chimneySweepingCertificates/create`}
                color="primary"
                variant="contained"
              >
                Tanúsítvány létrehozása{' '}
              </Button>
            </Grid>
          )}{' '}
        </Grid>
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ChimneyControlAppointmentPage'}
            exportToExcel={() => exportToExcel(rows)}
          />
        </Grid>
        <ConfirmDeleteDialog />
        <ChimneyControlAppointmentDialog
          entity={chimneyControlAppointmentDialog.entity}
          onSubmit={(appointment) => {
            fetchData();
          }}
          readonly={chimneyControlAppointmentDialog.readonly}
          open={chimneyControlAppointmentDialog.open}
          isOfferMandatory={chimneyControlAppointmentDialog.isOfferMandatory}
          specialNeeds={chimneyControlAppointmentDialog.specialNeeds}
          hasClientSpecialNeeds={
            chimneyControlAppointmentDialog.hasClientSpecialNeeds
          }
          onClose={() => {
            setChimneyControlAppointmentDialog({
              open: false,
              entity: {},
              chimneySweepingCertificateId: 0,
            });
          }}
          chimneySweepingCertificateId={
            chimneyControlAppointmentDialog.chimneySweepingCertificateId
          }
        />
      </Grid>
    </Paper>
  );
};

export default ChimneyControlAppointmentPage;
