import { Button, Grid, TextField, Autocomplete } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import clientService from '../../../services/crm/clientService';
import AddressForm from './AddressForm';
import { add, set } from 'date-fns';
import { AddressTypes } from '../../../types/AddressTypes';

const addressInit = {
  id: 0,
  country: 'Magyarország',
  postalCode: '',
  city: '',
  street: '',
  houseNumber: '',
  district: '',
  comment: '',
  isBillingAddress: false,
  type: AddressTypes.Site,
};

const ClientAddressCreate = (props: any) => {
  const {
    onSave = null,
    navigateBack = true,
    handleCancel = null,
    clientId,
    hasBillingAddress = false,
    canEditExisting = true,
    unselectableAddresses = [],
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [addresses, setAddresses] = useState<any[]>([]);
  const [address, setAddress] = useState(addressInit);

  const [submitting, setSubmitting] = useState(false);

  const { checkPermission } = useCheckPermission();

  const onSubmit = () => {
    dispatch({ type: 'SHOW_SAVE' });
    clientService
      .createAddress({ ...address, clientId })
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés', {
            variant: 'success',
          });
          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  useEffect(() => {
    clientService.listAddresses().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setAddresses(response.records);
      }
    });
  }, []);

  return (
    <Grid container spacing={5} p={5}>
      <Grid item>Cím hozzáadása</Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="addressId"
          value={address ?? ''}
          onChange={(event, value) => {
            if (value) setAddress(value);
            else setAddress(addressInit);
          }}
          getOptionLabel={(option) => {
            var found = addresses.find((x) => x.id === option.id);
            if (found) {
              return found.fullAddress;
            } else return 'Nem található';
          }}
          options={addresses.filter(
            (x) => unselectableAddresses.indexOf(x.id) < 0
          )}
          renderInput={(params) => (
            <TextField {...params} label="Cím" required fullWidth />
          )}
        />
      </Grid>
      <Grid item>
        <AddressForm
          address={address}
          readonly={address?.id > 0 && !canEditExisting}
          hasBillingAddress={hasBillingAddress}
          onAddressChange={(address: any) => setAddress(address)}
        />
      </Grid>
      <Grid
        container
        item
        pt={3}
        justifyContent="left"
        style={{
          position: 'sticky',
          bottom: 0,
          background: 'white',
          zIndex: 20,
        }}
      >
        <Grid item p={1}>
          <Button
            onClick={() => onSubmit()}
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Mentés
          </Button>
        </Grid>

        <Grid item p={1}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleCancel != null ? handleCancel() : navigate(-1);
            }}
          >
            Mégse
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ClientAddressCreate;
