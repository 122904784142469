import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useCheckPermission from '../../../hooks/useCheckPermission';
import SerialNumberedBlockForm from './SerialNumberedBlockForm';
import serialnumberedblockService from '../../../services/deviceManagement/serialNumberedBlockService';
import clientService from '../../../services/crm/clientService';
import userService from '../../../services/authority/userService';
import { set } from 'date-fns';
import { ClientTypes } from '../../../types/ClientTypes';
import warehouseService from '../../../services/wms/warehouseService';
import serialnumberedBlocktypeService from '../../../services/deviceManagement/serialNumberedBlockTypeService';
import { CompanyAssetStates } from '../../../types/InstrumentStates';

const SerialNumberedBlockCreate = (props: any) => {
  const { onSave = null, navigateBack = true, handleCancel = null } = props;

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [errors, setErrors] = useState<string[]>([]);
  const [now, setNow] = useState<Date>(new Date());
  const [users, setUsers] = useState<any[]>([]);
  const [clients, setClients] = useState<any[]>([]);
  const [warehouses, setWarehouses] = useState<any[]>([]);
  const [blockTypes, setBlockTypes] = useState<any[]>([]);
  const [entity, setEntity] = useState<any>({
    startNumber: 0,
    endNumber: 0,
    openingDate: now,
    closingDate: null,
    serialNumberedBlockTypeId: 0,
    companyId: 0,
    holderId: 0,
    warehouseId: 0,
    netPrice: 0,
    purchaseDate: now,
    guarantee: '',
    state: CompanyAssetStates.Working,
    isMassCreate: false,
    numberOfPages: 0,
    isClosed: false,
  });

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    warehouseService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setWarehouses(response.records);
      }
    });
  }, []);

  useEffect(() => {
    serialnumberedBlocktypeService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setBlockTypes(response.records);
      }
    });
  }, []);

  useEffect(() => {
    userService.list().then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        setUsers(response.records);
      }
    });
  }, []);

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    clientService
      .list(true, null)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          setClients(response.records);
          setEntity({
            ...entity,
            companyId: response.records.filter(
              (x) => x.clientType === ClientTypes.Distributor
            )[0].id,
          });
        }
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const onSubmit = (entity: any, setSubmitting: any) => {
    dispatch({ type: 'SHOW_SAVE' });
    serialnumberedblockService
      .create(entity)
      .then((response) => {
        if (response.canceled) return;
        if (response.hasError) {
          setErrors(response.errorMessages);
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
        } else {
          enqueueSnackbar('Sikeres mentés!', {
            variant: 'success',
          });

          if (onSave) onSave(response.result);
          if (navigateBack) navigate(-1);
        }
      })
      .finally(() => {
        dispatch({ type: 'HIDE' });
        setSubmitting(false);
      });
  };

  return (
    <SerialNumberedBlockForm
      entity={entity}
      readonly={!checkPermission(['SerialNumberedBlockCreate'])}
      clients={clients}
      users={users}
      warehouses={warehouses}
      blockTypes={blockTypes}
      onSubmit={onSubmit}
      errors={errors}
      handleCancel={() =>
        handleCancel != null ? handleCancel() : navigate(-1)
      }
    />
  );
};

export default SerialNumberedBlockCreate;
