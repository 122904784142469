import axios from 'axios';
import { postData } from '../axiosUtils';

const serialnumberedBlockService = {
  list: (
    filterTypeId?: number | null,
    signal: AbortSignal = new AbortController().signal
  ) => {
    let url = `/api/serialnumberedblock/list`;
    if (filterTypeId) {
      url = `/api/serialnumberedblock/list?filterTypeId=${filterTypeId}`;
    }
    return axios.get(url, { signal }).then((response) => {
      return response.data;
    });
  },
  get: (id: any) => {
    return axios
      .get(`/api/serialnumberedblock/details/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  create: (entity: any) => {
    return postData({
      url: `/api/serialnumberedblock/create`,
      data: entity,
    }).then((response) => {
      return response;
    });
  },
  update: (entity: any) => {
    return postData({
      url: `/api/serialnumberedblock/update`,
      data: entity,
      method: 'PUT',
    }).then((response) => {
      return response;
    });
  },
  delete: (id: any) => {
    return postData({
      url: `/api/serialnumberedblock/delete/${id}`,
      method: 'DELETE',
    }).then((response) => {
      return response;
    });
  },
};

export default serialnumberedBlockService;
