import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField, Autocomplete, Grid, InputAdornment } from '@mui/material';
import ItemCreate from '../wms/items/ItemCreate';
import CreateEntityDecorator from '../../components/CreateEntityDecorator';
import useCheckPermission from '../../hooks/useCheckPermission';
import { useEffect } from 'react';
import { currencyAdornment } from '../../utils/valueFormatters';

export default function AddItemDialog(props: any) {
  const { open, items, handleClose, onAdd, onNewItemAdded, taxes, currency } =
    props;
  const [item, setItem] = React.useState<any>({
    id: '',
    name: '',
    unitPrice: '',
    count: '',
    externalItemNumber: '',
    taxTypeId: taxes?.[0]?.id ?? 0,
  });
  const { checkPermission } = useCheckPermission();

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Tétel felvétele</DialogTitle>
      <DialogContent>
        <Grid pt={3} container spacing={2} style={{ minHeight: '20vh' }}>
          <Grid item xs={12}>
            <CreateEntityDecorator
              hideAdd={!checkPermission(['ItemCreate'])}
              AutocompleteComponent={
                <Autocomplete
                  disablePortal
                  id="itemId"
                  value={item.id}
                  onChange={(event, value) => {
                    let item = items.find((x) => x.id === value);

                    if (item)
                      setItem({
                        id: '',
                        name: '',
                        unitPrice: 0,
                        ...item,
                        count: 1,
                        externalItemNumber: item.externalItemNumber ?? '',
                        taxTypeId:
                          item.taxTypeId > 0
                            ? item.taxTypeId
                            : (taxes?.[0]?.id ?? 0),
                      });
                  }}
                  getOptionLabel={(option) => {
                    var item = items.find((g) => g.id === option);
                    if (item === null || item === undefined) {
                      return '';
                    }

                    return `${item.name} (${
                      item.externalItemNumber ?? item.itemNumber
                    })`;
                  }}
                  options={items.map((g) => g.id)}
                  filterOptions={(options, params) => {
                    // Convert the search term to lowercase for case-insensitive search
                    const searchTermLower = params.inputValue.toLowerCase();

                    // Perform a fuzzy search by splitting the search term into tokens
                    const searchTokens = searchTermLower.split(/\s+/);

                    // Filter items based on the search tokens
                    const filteredItems = items.filter((item) => {
                      // Convert the item name to lowercase
                      let name = `${item.name} (${
                        item.externalItemNumber ?? item.itemNumber
                      })`;
                      const itemNameLower = name.toLowerCase();

                      // Check if each token is present in the item name
                      return searchTokens.every((token) =>
                        itemNameLower.includes(token)
                      );
                    });

                    return filteredItems.map((item) => item.id);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Termék"
                      fullWidth
                    />
                  )}
                />
              }
              onSave={(item) => {
                setItem(item);
                onNewItemAdded(item);
              }}
              CreateEntityComponent={ItemCreate}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              value={item.externalItemNumber}
              onChange={(event) => {
                setItem({ ...item, externalItemNumber: event.target.value });
              }}
              required
              label="Beszállítói cikkszám"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Nettó egységár"
              required
              type="number"
              onChange={(event) => {
                setItem({ ...item, unitPrice: event.target.value });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {' '}
                    {currencyAdornment(currency)}
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={item.unitPrice}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              type="number"
              fullWidth
              onChange={(event) => {
                setItem({ ...item, count: event.target.value });
              }}
              label="Mennyiség"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
              value={item.count}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="taxTypeId"
              value={item.taxTypeId}
              onChange={(event, value) => {
                setItem({ ...item, taxTypeId: value });
              }}
              getOptionLabel={(option) => {
                var tax = taxes?.find((g) => g.id === option);
                if (tax === null || tax === undefined) {
                  return '';
                }

                return `${tax.name} (${tax.value}%)`;
              }}
              options={taxes?.map((g) => g.id) ?? []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Adótípus"
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!item.id}
          onClick={() => {
            onAdd(item);
            setItem({
              id: '',
              name: '',
              unitPrice: '',
              count: '',
              externalItemNumber: '',
              taxTypeId: 0,
            });
          }}
        >
          Hozzáadás
        </Button>
        <Button color="error" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
