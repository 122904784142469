import {
  GridColDef,
  GridActionsCellItem,
  GridActionsColDef,
} from '@mui/x-data-grid';
import { Paper, IconButton, Grid, Tooltip } from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
  Visibility,
} from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import DataList from '../../../components/DataList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useCheckPermission from '../../../hooks/useCheckPermission';
import useFunctionDescriptor from '../../../hooks/useFunctionDescriptor';
import useConfirmDeleteDialog from '../../../hooks/useConfirmDeleteDialog';
import chimneyService from '../../../services/chimneySweeping/chimneyService';
import { EntityTypes } from '../../../types/EntityTypes';
import EntityNavigator from '../../../components/EntityNavigator';
import { parseJSON } from 'date-fns';
import * as XLSX from 'xlsx';

export interface Chimney {
  id: number;
  usingSinceDate: Date;
  addressId: number;
  chimneyTypeId: number;
  isInUse: boolean;
  hasBeenUsed: boolean;
  isBanned: boolean;
}

const ChimneyPage = () => {
  const navigate = useNavigate();
  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [rows, setRows] = useState<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch<any>();
  const titleDescriptor = useFunctionDescriptor('ChimneysPage.title');

  const { checkPermission } = useCheckPermission();

  useEffect(() => {
    dispatch({ type: 'SHOW_QUERY' });
    chimneyService
      .list()
      .then((response) => {
        if (response.canceled) return;
        if (!response.hasError) setRows(response.records);
        else
          enqueueSnackbar(response.errorMessages.join(','), {
            variant: 'error',
          });
      })
      .finally(() => dispatch({ type: 'HIDE' }));
  }, []);

  const getActions = (params: GridRowParams, color: any) => {
    var actions = [];

    let readonly = !checkPermission(['ChimneyEdit']);
    actions.push(
      <GridActionsCellItem
        color={color ? color : 'primary'}
        icon={
          readonly ? (
            <Tooltip title="Megtekintés">
              <Visibility />
            </Tooltip>
          ) : (
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          )
        }
        label={readonly ? 'Megtekintés' : 'Szerkesztés'}
        onClick={() => navigate(`/erp/chimneys/edit/${params.row.id}`)}
      />
    );

    if (checkPermission(['ChimneyDelete'])) {
      actions.push(
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Törlés"
          onClick={() =>
            setParams({
              open: true,
              name: '',
              onConfirm: async () => handleDelete(params.row.id),
            })
          }
        />
      );
    }

    return actions;
  };

  const columns: GridColDef[] = [
    {
      field: 'clientName',
      headerName: 'Ügyfél',
      flex: 200,
      renderCell(params) {
        return (
          <EntityNavigator
            entityType={EntityTypes.Client}
            entityId={params.row.clientId}
            value={params.row.clientName}
          />
        );
      },
      valueGetter: (params: any) => {
        return params.row.clientName;
      },
    },
    { field: 'fullAddress', headerName: 'Cím', flex: 200 },
    {
      field: 'typeName',
      headerName: 'Típus',
      flex: 150,
      valueGetter(params) {
        return params.row.typeName.startsWith('K') &&
          params.row.connectionElementLength
          ? `${params.row.typeName}(${params.row.connectionElementLength} fm)`
          : params.row.typeName;
      },
    },
    {
      field: 'groupNumber',
      headerName: 'Csoport/járat számjelzése',
      flex: 150,
    },
    {
      field: 'usingSinceDate',
      headerName: 'Használatba vétel dátuma',
      valueFormatter(params) {
        return params.value
          ? new Date(params.value as string).toLocaleDateString()
          : '-';
      },
      flex: 200,
    },
    {
      field: 'lastControlYear',
      headerName: 'Utolsó ellenőrzés éve',
      flex: 100,
    },
    {
      field: 'isInUse',
      headerName: 'Használatban van',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },
    {
      field: 'hasBeenUsed',
      headerName: 'Volt már használatban',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },
    {
      field: 'isBanned',
      headerName: 'Tiltott',
      valueFormatter(params) {
        return params.value ? 'Igen' : 'Nem';
      },
      flex: 150,
    },

    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      getActions: getActions,
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number) => {
    chimneyService.delete(id).then((response) => {
      if (response.canceled) return;
      if (response.hasError) {
        enqueueSnackbar(response.errorMessages.join(','), {
          variant: 'error',
        });
      } else {
        enqueueSnackbar('Sikeres törlés', {
          variant: 'success',
        });
        setRows(rows.filter((row) => row.id !== id));
      }
    });
  };

  function convertObjectsToArrays(objects: any[]) {
    if (!Array.isArray(objects)) {
      throw new Error('Input must be an array of objects');
    }

    if (objects?.length === 0) {
      return [];
    }

    const translationMap = {
      clientName: 'Ügyfél',
      fullAddress: 'Cím',
      typeName: 'Típus',
      groupNumber: 'Csoport/járat számjelzése',
      usingSinceDate: 'Használatba vétel dátuma',
      lastControlYear: 'Utolsó ellenőrzés éve',
      isInUse: 'Használatban van',
      hasBeenUsed: 'Volt már használatban',
      isBanned: 'Tiltott',
      connectionElementLength: 'Összekötő elem hossza',
    };

    const filteredKeys = [
      'clientName',
      'fullAddress',
      'typeName',
      'groupNumber',
      'lastControlYear',
      'connectionElementLength',
      'isInUse',
      'hasBeenUsed',
      'isBanned',
      'usingSinceDate',
    ];
    const headers = filteredKeys.map((key) =>
      translationMap[key]?.toUpperCase()
    );
    const rows = objects.map((object) => {
      if (typeof object !== 'object') {
        throw new Error('Elements of the input array must be objects');
      }

      return filteredKeys.map((key) => {
        const value = object[key];
        if (!value) return '';
        let isNumber = !isNaN(parseInt(value));
        if (!isNaN(Date.parse(value)) && !isNumber) {
          const date = new Date(value);
          if (isNaN(date.getTime())) {
            return parseJSON(value).toLocaleDateString();
          }
          return parseJSON(value).toLocaleDateString();
        }
        if (typeof value === 'boolean') {
          return value ? 'Igen' : 'Nem';
        }
        return value;
      });
    });

    return [headers, ...rows];
  }
  function exportToExcel(data: any) {
    var array = convertObjectsToArrays(data);
    const ws = XLSX.utils.aoa_to_sheet(array);

    // Calculate column widths based on content length and header length
    const columnWidths = array[0].map((header, columnIndex) => {
      const columnValues = array.map((row) => String(row[columnIndex]));
      const headerLength = header?.length;
      const maxContentLength = Math.max(
        headerLength,
        ...columnValues.map((value) => value?.length)
      );
      return maxContentLength + 2; // Add extra space for readability
    });

    // Set column widths
    ws['!cols'] = columnWidths.map((width) => ({ width }));

    Object.keys(ws).forEach((cellAddress) => {
      if (cellAddress[0] === '!') return; // Skip metadata properties
      const cell = ws[cellAddress];
      if (cell) {
        cell.s = {
          alignment: {
            horizontal: 'center',
            vertical: 'center',
          },
        };
      }
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Adatsor');
    const fileName = `Kémények.xlsx`;
    XLSX.writeFile(wb, fileName);
  }

  return (
    <Paper>
      <Grid container p={3}>
        <Grid item xs={12}>
          <h2>Kémények{titleDescriptor}</h2>
        </Grid>
        {checkPermission(['ChimneyCreate']) && (
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Tooltip title="Kémény létrehozása">
                <IconButton
                  component={RouterLink}
                  to={`/erp/chimneys/create`}
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <DataList
            rows={rows}
            columns={columns}
            localStorageKey={'ChimneysPage'}
            exportToExcel={() => exportToExcel(rows)}
          />
        </Grid>
        <ConfirmDeleteDialog />
      </Grid>
    </Paper>
  );
};

export default ChimneyPage;
