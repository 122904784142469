import {
  DialogContent,
  useMediaQuery,
  DialogActions,
  TextField,
  DialogTitle,
  Grid,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { useState } from 'react';

export default function StornoCreateDialog(props: any) {
  const { open, setOpen, onSave = () => {} } = props;
  const isSmallScreen = useMediaQuery('(min-width:600px)');
  const [documentNumber, setDocumentNumber] = useState('');

  const handleCancel = () => {
    setOpen(false);
    setDocumentNumber('');
  };

  return (
    <Dialog
      open={open}
      style={{ minHeight: 500 }}
      fullWidth
      maxWidth="md"
      onClose={handleCancel}
      fullScreen={!isSmallScreen}
    >
      <DialogTitle>Sztornó számla létrehozása</DialogTitle>
      <DialogContent>
        <Grid pt={2}>
          <TextField
            fullWidth
            label="Számlaszám"
            variant="outlined"
            type="number"
            value={documentNumber}
            onChange={(e) => setDocumentNumber(e.target.value)}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onSave(documentNumber);
            handleCancel();
          }}
        >
          Mentés
        </Button>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => handleCancel()}
        >
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
